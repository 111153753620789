import React from "react";
import MakeAppointmentButton from "../../../../components/MakeAppointmentButton";

const ProjectsSectionHeader: React.FC = () => {
  return (
    <div className="project__header">
      <h2 className="projects__heading">Nos derniers projets</h2>
      <MakeAppointmentButton showAvatar />
    </div>
  );
};

export default ProjectsSectionHeader;
