import React from "react";
import ReactDOM from "react-dom/client";
import "./css/globals.scss";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { WhatsAppWidget } from "./components/WhatsAppWidget/src";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />

    <WhatsAppWidget phoneNumber="33651581106" />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
