/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const contactLinks = [
  {
    href: "tel:0531600991",
    imgSrc: "images/phoneCall.png",
    imgAlt: "Call Icon",
    text: "05 31 60 09 91",
  },
  {
    href: "#",
    imgSrc: "images/pinLocation.png",
    imgAlt: "Location Icon",
    text: "44 rue Minvielle, 33000, Bordeaux",
  },
  {
    href: "mailto:hello@minuit.agency?subject=minuit.agency",
    imgSrc: "images/formkit_email.png",
    imgAlt: "Email Icon",
    text: "hello@minuit.agency",
  },
];

const HeroContactLinks = () => {
  return (
    <div className="contact-wrapper">
      {contactLinks.map((link, index) => (
        <a key={index} href={link.href} className="contact-link w-inline-block">
          <img
            loading="lazy"
            src={link.imgSrc}
            alt={link.imgAlt}
            style={{
              objectFit: "contain",
              width: "24px",
              height: "24px",
              marginRight: "4px",
            }}
          />
          <div className="text-block-8">{link.text}</div>
        </a>
      ))}
    </div>
  );
};

export default HeroContactLinks;
