import React from "react";
import GeneralConditionSection from "./GeneralConditionSection";

const GeneralCondition: React.FC = () => {
  return (
    <React.Fragment>
      <GeneralConditionSection />
    </React.Fragment>
  );
};

export default GeneralCondition;
