import React from "react";

type Props = {
  projectBrandImgSrc?: string;
  projecTitle?: string;
  projectDescription?: string;
  projectCardImgSrc?: string;
};

const ProjectCard: React.FC<Props> = ({
  projectBrandImgSrc,
  projecTitle,
  projectDescription,
  projectCardImgSrc,
}) => {
  return (
    <div className="project__card">
      <div className="project__card-header">
        <div className="icon-with-text">
          <img
            loading="lazy"
            src={projectBrandImgSrc}
            alt=""
            className="project__card-brand"
          />
          <h5 className="project__card-title">{projecTitle}</h5>
        </div>
        <p className="paragraph-3">{projectDescription}</p>
      </div>
      <div className="project__card-image">
        <img
          loading="lazy"
          src={projectCardImgSrc}
          alt=""
          className="project__image"
        />
      </div>
    </div>
  );
};

export default ProjectCard;
