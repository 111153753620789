import React, { useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";

// import { Image } from "shared/components/";

import { ChatContext } from "../../contexts/chat.context";
import styles from "./header.module.css";

const defaultProps = {
  companyName: "minuit.agency",
  replyTimeText: "Répond en quelques minutes",
};

const Header = ({
  CompanyIcon,
  companyName = defaultProps.companyName,
  replyTimeText = defaultProps.replyTimeText,
}) => {
  const { setIsChatOpen } = useContext(ChatContext);

  const handleOpen = () => {
    setIsChatOpen(false);
  };

  return (
    <div className={styles.root}>
      <span className={styles.close_btn} onClick={handleOpen}>
        <AiOutlineClose />
      </span>
      <div className={styles.logo}>
        <img
          src={"images/logo.png"}
          alt="Logo de minuit.agency"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "50%",
          }}
        />
      </div>
      <div className={styles.texts}>
        <span className={styles.texts_h1}>{companyName}</span>
        <span className={styles.texts_span}>{replyTimeText}</span>
      </div>
    </div>
  );
};

export default Header;
