import React from "react";
import ImageUser from "../../../../../components/ImageUser";

const BentoCardTeamMembers: React.FC = () => {
  return (
    <div className="bento__card glowing_border">
      <img
        loading="lazy"
        src="images/blur-top.svg"
        alt=""
        className="blur-top-right"
      />
      <div
        id="w-node-_985923c8-6e59-a42f-7586-5b60eac6eb8c-df22aa20"
        className="circle-ripple"
      />
      <h3 className="bento-cad__title">
        Une équipe 100% dédiée à votre projet
      </h3>
      <div className="image-user-container">
        <ImageUser src="images/team.png" className="is-first" />
        <ImageUser src="images/team-1.png" />
        <ImageUser src="images/team-2.png" />
        <ImageUser src="images/team-3.png" />
        <ImageUser src="images/team-4.png" />
      </div>
      <img
        loading="lazy"
        src="images/users-mobile.png"
        alt=""
        className="image-users--mobile"
      />
    </div>
  );
};

export default BentoCardTeamMembers;
