import React, { useEffect } from "react";
import CollectionItem from "../../../../components/CollectionItem/CollectionItem";
import EmptyState from "../../../../components/EmptyState";
import MakeAppointmentButton from "../../../../components/MakeAppointmentButton";

import collections from "../../../../data/collections.json";
import Each from "../../../../components/Each";

const AppSectionContent: React.FC = () => {
  const DOMContentLoaded = () => {
    const appItems = document.querySelectorAll(".app__item");
    const appImages = document.querySelectorAll(".app__image");

    let currentSlide = 0;
    // Check if there are items and images
    if (appItems.length && appImages.length) {
      // Set the first item and image as active by default
      appItems[0].classList.add("active");
      appImages[0].classList.add("active");
      // Add click event for each appItem
      appItems.forEach((appItem, index) => {
        appItem.addEventListener("click", function () {
          // Remove the active class from all items and images
          appItems.forEach((item, i) => {
            item.classList.remove("active");
            appImages[i].classList.remove("active");
          });
          // Add the active class to the clicked item and corresponding image
          appItem.classList.add("active");
          appImages[index].classList.add("active");
          // Update currentSlide index to the clicked item
          currentSlide = index;
        });
      });
    }
    // Function to move to the next slide automatically
    function nextSlide() {
      // Remove active class from current slide
      appItems[currentSlide].classList.remove("active");
      appImages[currentSlide].classList.remove("active");
      // Move to the next slide, looping back to the first if at the end
      currentSlide = (currentSlide + 1) % appItems.length;
      // Add active class to the next slide
      appItems[currentSlide].classList.add("active");
      appImages[currentSlide].classList.add("active");
    }
    // Trigger next slide every 8 seconds
    setInterval(nextSlide, 8000);
  };

  useEffect(() => {
    DOMContentLoaded();
  }, []);

  return (
    <div className="app__content">
      <div className="handleitemclick w-embed w-script"></div>
      <div className="content-list w-dyn-list">
        {collections?.length && collections?.length > 0 ? (
          <div role="list" className="collection-list w-dyn-items">
            <Each
              of={collections}
              render={(collection, index) => (
                <CollectionItem
                  title={collection?.title}
                  description={collection?.description}
                  key={`collection_${index}`}
                />
              )}
            />
          </div>
        ) : (
          <EmptyState />
        )}
      </div>

      <div className="button-group mobile-hidden">
        <MakeAppointmentButton showAvatar />
      </div>
    </div>
  );
};

export default AppSectionContent;
