import React from "react";

import "./LegalSections.scss";

const LegalSections: React.FC = () => {
  return (
    <section className="section-container">
      <div className="container">
        <h1 className="heading-4">Mentions légales</h1>
        <p className="paragraph">
          Conformément aux dispositions des articles 6-III et 19 de la Loi pour
          la Confiance dans l’Economie Numérique, nous vous informons que le
          présent site est édité par :
        </p>
        <h2 className="article__heading">
          SASU minuit.agency - nom commercial minuit.agency
        </h2>
        <p className="paragraph">
          SASU minuit.agency est une filiale de minuit.holdingsImmatriculée au
          Registre du Commerce de Bordeaux
          <br />
          Capital Social : 1 000,00 €<br />
          Adresse: 44 rue Minvielle, 33000, Bordeaux
          <br />
          N° SIRET RCS, 91928949600011 – 33300 Bordeaux
          <br />
          <br />
          Tel: 05 31 60 09 91
          <br />
          Email: hello@minuit.agency
          <br />
          L’hébergement du site minuit.agency est assuré par:
          <br />
          Vercel Inc. 340 S Lemon Ave #4133 Walnut, CA 91789.
          <br />
          Directeur de publication : M. Théo HUDRY.
        </p>
        <h2 className="article__heading">Politique de confidentialité</h2>
        <p className="paragraph">
          En aucun cas, les données recueillies sur le site www.agenceminuit.com
          ne seront cédées ou vendues à des tiers. Aucune adresse email ne sera
          transmise à des tiers y compris à nos partenaires sauf avec l’accord
          écrit des intéressés.
        </p>
        <h2 className="article__heading">
          Propriétés intellectuelles et marque déposée
        </h2>
        <p className="paragraph">
          La marque minuit.agency et le site www.agenceminuit.com sont la
          propriété exclusive de la société SASU minuit.agency, seule habilitée
          à utiliser et exploiter les droits de propriété intellectuelle et
          droits de la personnalité attachés à ce site, notamment les marques,
          concepts, dessins et modèles, droits d’auteur et droits à l’image, à
          titre originaire ou par l’effet d’une cession ou d’une licence
          d’exploitation. L’usage de tout ou partie du site
          www.agenceminuit.com, notamment par téléchargement, reproduction,
          transmission ou représentation à d’autres fins que pour usage
          personnel et privé dans un but non commercial est strictement
          interdit.
        </p>
        <h2 className="article__heading">Responsabilité en cas de problème</h2>
        <p className="paragraph">
          Ce site ne saurait en aucun cas être tenu pour responsable des
          erreurs, des problèmes techniques rencontrés sur le site et sur tous
          les autres sites vers lesquels nous établissons des liens, ou de toute
          interprétation des informations publiées sur ces sites, ainsi que des
          conséquences de leur utilisation. La loi française est applicable tant
          pour les règles de forme que de fond.
        </p>
      </div>
    </section>
  );
};

export default LegalSections;
