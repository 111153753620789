import React from "react";
import MakeAppointmentButton from "../../../../components/MakeAppointmentButton";

const BentoSectionLeftContent: React.FC = () => {
  return (
    <div className="bento__row--flex">
      <div className="bento__card--flex--bottom">
        <div className="bento__left-content">
          <h3 className="bento-cad__title-2 title--wide-copy">
            Faites de vos idées une réalité
          </h3>
        </div>
        <div
          id="w-node-_36b5b5e8-ac7a-53bb-6da1-ce0eece331a0-df22aa20"
          className="bento__right-content"
        >
          <MakeAppointmentButton showAvatar />
        </div>
        <img
          loading="lazy"
          src="images/Line-6.svg"
          alt=""
          className="image-7"
        />
      </div>
    </div>
  );
};

export default BentoSectionLeftContent;
