import React from "react";
import TestimonialSectionHeader from "./TestimonialSectionHeader";
import TestimonialSectionContent from "./TestimonialSectionContent";

const TestimonialSection: React.FC = () => {
  return (
    <section id="avis" className="section-testimonial">
      <div className="testimonial-container">
        <div
          id="w-node-_5c39e34e-3e22-dbaa-6872-00393ef4aabb-df22aa20"
          className="overlay-top-gradient"
        ></div>
        <TestimonialSectionHeader />
        <TestimonialSectionContent />
      </div>
      <img
        loading="lazy"
        src="images/meshGradientTestimonial.svg"
        alt=""
        className="meshgradienttestomonial"
      ></img>
    </section>
  );
};

export default TestimonialSection;
