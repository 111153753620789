import React from "react";
import BentoCardTeam from "./BentoCardTeam/BentoCardTeam";
import BentoCardTechnologies from "./BentoCardTechnologies";
import BentoSectionContentMobile from "./BentoSectionContentMobile";
import BentoSectionContentCardMobile from "./BentoSectionContentCardMobile";
import BentoSectionLeftContent from "./BentoSectionLeftContent";

const BentoSectionContent = () => {
  return (
    <div className="bento-content">
      <BentoCardTeam />
      <BentoCardTechnologies />
      <BentoSectionContentMobile />
      <BentoSectionContentCardMobile />
      <BentoSectionLeftContent />
    </div>
  );
};

export default BentoSectionContent;
