"use client";

import React from "react";

import "./CollectionItem.scss";

type Props = {
  title?: string;
  description?: string;
};

const CollectionItem: React.FC<Props> = ({ title, description }) => {
  return (
    <div role="listitem" className="collection-item-2 w-dyn-item">
      <div className="app__item">
        <div className="progress-container">
          <div className="progressbar"></div>
        </div>
        <div className="app__item-content">
          <h4 className="app__card__title">{title}</h4>
          <p className="app__card__text">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default CollectionItem;
