import React, { useState } from "react";
import styles from "./send-button.module.css";

const defaultProps = {
  sendButtonText: "Envoyer",
  inputPlaceHolder: "Saisissez votre message ici",
};

const SendButton = ({
  phoneNumber,
  inputPlaceHolder = defaultProps.inputPlaceHolder,
  sendButtonText = defaultProps.sendButtonText,
}) => {
  const [message, setMessage] = useState("");

  const handleClick = () => {
    if (!phoneNumber) {
      window.alert("Numéro de téléphone invalide");
      return false;
    }

    window.open(
      `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${message}&type=phone_number&app_absent=0`,
      "_blank"
    );
    setMessage("");
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  return (
    <div className={styles.root}>
      <input
        placeholder={inputPlaceHolder}
        className={styles.input}
        onChange={handleChange}
        value={message}
      />
      <button className={styles.button} onClick={handleClick}>
        {sendButtonText}
      </button>
    </div>
  );
};

export default SendButton;
