import React from "react";

import ScrollContainer from "../../../../components/ScrollContainer";
import TestimonialColumns from "../../../../components/TestimonialColumns";

import testimonialData from "../../../../data/testimonials.json";

const testimonyColumnList = [
  {
    id: "w-node-b8a5eee5-4cf4-f35c-7a50-f8b188c398bc-df22aa20",
    className: "mobile-hidden",
    itemId: "w-node-b8a5eee5-4cf4-f35c-7a50-f8b188c398bd-df22aa20",
    wId: "b8a5eee5-4cf4-f35c-7a50-f8b188c398bd",
    itemStyle: {
      WebkitTransform:
        "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
      MozTransform:
        "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
      transform:
        "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    },
  },
  {
    id: "w-node-b8a5eee5-4cf4-f35c-7a50-f8b188c398ca-df22aa20",
    className: "scroll-second-item mobile-hidden",
    itemId: "w-node-b8a5eee5-4cf4-f35c-7a50-f8b188c398cb-df22aa20",
    wId: "b8a5eee5-4cf4-f35c-7a50-f8b188c398cb",
    itemStyle: {
      WebkitTransform:
        "translate3d(0, -50%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
      MozTransform:
        "translate3d(0, -50%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
      transform:
        "translate3d(0, -50%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    },
  },
  {
    id: "w-node-b8a5eee5-4cf4-f35c-7a50-f8b188c398d8-df22aa20",
    className: "mobile-hidden is-tablet-hidden",
    itemId: "w-node-b8a5eee5-4cf4-f35c-7a50-f8b188c398d9-df22aa20",
    wId: "b8a5eee5-4cf4-f35c-7a50-f8b188c398d9",
    itemStyle: {
      WebkitTransform:
        "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
      MozTransform:
        "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
      transform:
        "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    },
  },
  {
    id: "w-node-b8a5eee5-4cf4-f35c-7a50-f8b188c398e6-df22aa20",
    className: "desktop-hidden",
    itemId: "w-node-b8a5eee5-4cf4-f35c-7a50-f8b188c398e7-df22aa20",
    wId: "b8a5eee5-4cf4-f35c-7a50-f8b188c398e7",
    itemStyle: {
      WebkitTransform:
        "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
      MozTransform:
        "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
      transform:
        "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    },
  },
];

const TestimonialSectionContent: React.FC = () => {
  return (
    <div className="testimonial__content">
      {testimonyColumnList.map((data) => {
        const randomTestimonyData = [...testimonialData].sort(
          () => Math.random() - 0.5
        );

        return (
          <ScrollContainer
            key={data.id}
            id={data.id}
            className={data.className}
            itemId={data.itemId}
            wId={data.wId}
            itemStyle={data.itemStyle}
          >
            {randomTestimonyData.map((data) => (
              <TestimonialColumns key={data?.projectName} {...data} />
            ))}
          </ScrollContainer>
        );
      })}
      <div
        id="w-node-_01c42fab-3206-be56-c1ab-fcc7392fa4e8-df22aa20"
        className="overlay-top desktop-hidden"
      ></div>
      <div
        id="w-node-b8a5eee5-4cf4-f35c-7a50-f8b188c398f5-df22aa20"
        className="overlay-bottom"
      ></div>
    </div>
  );
};

export default TestimonialSectionContent;
