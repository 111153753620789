import ChatTypingDot from "../../../../../components/ChatTypingDot/ChatTypingDot";
import "./BentoCardChatWithUs.scss";

import React, { useEffect } from "react";

let i = 0; // Current character index
let txt = "Bonjour David, je viens de terminer votre projet!"; // Text to type
let speed = 50; // Typing speed in milliseconds

const BentoCardChatWithUs: React.FC = () => {
  function typeWriter() {
    if (i < txt.length) {
      document!.getElementById("chat-typing-text")!.innerHTML += txt.charAt(i);
      i++;
      setTimeout(typeWriter, speed);
    } else {
      // Reset after 5 seconds
      setTimeout(resetTypeWriter, 5000);
    }
  }
  function resetTypeWriter() {
    // Clear the content and reset the index
    document!.getElementById("chat-typing-text")!.innerHTML = "";
    i = 0; // Reset index
    typeWriter(); // Start typing again
  }

  useEffect(() => {
    // Call the typeWriter function automatically when the page loads
    typeWriter();
  }, []);

  return (
    <div
      id="w-node-_36b5b5e8-ac7a-53bb-6da1-ce0eece33172-df22aa20"
      className="bento__card glowing_border"
    >
      <h3 className="bento-cad__title title--wide">
        Échangez en direct avec nous
      </h3>
      <img
        sizes="(max-width: 479px) 100vw, (max-width: 767px) 44vw, (max-width: 991px) 206px, 348px"
        srcSet="images/User-p-500.png 500w, images/User-p-800.png 800w, images/User-p-1080.png 1080w, images/User.png 1396w"
        alt=""
        src="images/User.png"
        loading="lazy"
        className="image-chat--desktop"
      />
      <div className="div-block-11">
        <div className="chat-bubble">
          <div id="chat-typing-text" className="chat-typing-text"></div>
        </div>
      </div>
      <div className="chat-bubble is-right">
        <ChatTypingDot />
      </div>
    </div>
  );
};

export default React.memo(BentoCardChatWithUs);
