"use client";

import ProjectsSectionHeader from "./ProjectsSectionHeader";
import FollowInstagram from "../../../../components/FollowInstagram";
import ProjectsSectionCollection from "./ProjectsSectionCollection";

const ProjectsSection = () => {
  return (
    <section id="realisations" className="section-projects">
      <img
        loading="lazy"
        src="images/blur-vector-1.svg"
        alt=""
        className="pr-blur-vector-1"
        style={{}}
      />
      <img
        loading="lazy"
        src="images/blur-vector-2.svg"
        alt=""
        className="pr-blur-vector-2"
      />

      <div className="project-container">
        <ProjectsSectionHeader />
        <ProjectsSectionCollection />
      </div>

      <FollowInstagram />
    </section>
  );
};

export default ProjectsSection;
