import React from "react";
import TestimonialCard from "./TestimonialCard";
// import TestimonialCardDyn from "./TestimonialCardDyn";
// import EmptyState from "./EmptyState";

type Props = {
  testimony?: string;
  clientName?: string;
  clientRole?: string;
  clientProfilePicture?: string;
  projectName?: string;
};

const TestimonialColumns: React.FC<Props> = ({
  testimony = "",
  clientName = "",
  clientRole = "",
  clientProfilePicture = "",
  projectName,
}) => {
  return (
    <div className="testimonial-column w-dyn-list">
      <div role="list" className={`testimonial-column w-dyn-items`}>
        <div role="listitem" className="w-dyn-item">
          <TestimonialCard
            testimony={testimony}
            clientName={clientName}
            clientRole={clientRole}
            clientProfilePicture={clientProfilePicture}
            projectName={projectName}
          />
        </div>
      </div>
    </div>
  );
};

export default TestimonialColumns;
