import * as React from "react";

const IconInstagramSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.9 44.8832C35.4333 44.8832 44.7828 35.5337 44.7828 24.0004C44.7828 12.4672 35.4333 3.11761 23.9 3.11761C12.3668 3.11761 3.01721 12.4672 3.01721 24.0004C3.01721 35.5337 12.3668 44.8832 23.9 44.8832Z"
      fill="url(#paint0_linear_50_2294)"
    />
    <path
      d="M28.3362 12.399H19.4726C15.5188 12.399 12.3075 15.6103 12.3075 19.5641V28.4277C12.3075 32.3815 15.5188 35.5928 19.4726 35.5928H28.3362C32.29 35.5928 35.5013 32.3815 35.5013 28.4277V19.5641C35.5013 15.6103 32.29 12.399 28.3362 12.399ZM32.9119 28.437C32.9119 30.9615 30.8607 33.0219 28.3269 33.0219H19.4633C16.9388 33.0219 14.8784 30.9708 14.8784 28.437V19.5734C14.8784 17.0489 16.9296 14.9885 19.4633 14.9885H28.3269C30.8514 14.9885 32.9119 17.0396 32.9119 19.5734V28.437Z"
      fill="white"
    />
    <path
      d="M23.8998 18.0704C20.6328 18.0704 17.9691 20.7341 17.9691 24.0011C17.9691 27.2681 20.6328 29.9318 23.8998 29.9318C27.1668 29.9318 29.8305 27.2681 29.8305 24.0011C29.8305 20.7341 27.1668 18.0704 23.8998 18.0704ZM23.8998 27.6022C21.9136 27.6022 20.2987 25.9873 20.2987 24.0011C20.2987 22.0149 21.9136 20.4 23.8998 20.4C25.886 20.4 27.501 22.0149 27.501 24.0011C27.501 25.9873 25.886 27.6022 23.8998 27.6022Z"
      fill="white"
    />
    <path
      d="M30.282 18.7194C30.8284 18.6309 31.1996 18.1161 31.111 17.5697C31.0224 17.0233 30.5077 16.6522 29.9613 16.7407C29.4149 16.8293 29.0437 17.3441 29.1323 17.8905C29.2209 18.4369 29.7356 18.808 30.282 18.7194Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_50_2294"
        x1={7.99929}
        y1={39.9012}
        x2={37.6761}
        y2={10.2244}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEE411" />
        <stop offset={0.0518459} stopColor="#FEDB16" />
        <stop offset={0.1381} stopColor="#FEC125" />
        <stop offset={0.2481} stopColor="#FE983D" />
        <stop offset={0.3762} stopColor="#FE5F5E" />
        <stop offset={0.5} stopColor="#FE2181" />
        <stop offset={1} stopColor="#9000DC" />
      </linearGradient>
    </defs>
  </svg>
);
export default IconInstagramSVG;
