import React from "react";

const TestimonialSectionHeader: React.FC = () => {
  return (
    <div className="testimonial__header">
      <div className="badge-clients">
        <img
          loading="lazy"
          src="images/_users_.png"
          alt=""
          className="icon-users"
        />
        <div className="badge-client-text">Recommandé par +100 clients</div>
      </div>
      <h2 className="testimonial__heading">Ils nous font confiance</h2>
    </div>
  );
};

export default TestimonialSectionHeader;
