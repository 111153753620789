import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "./pages/Home/Home";
import NavBar from "./components/Blocks/NavBar/NavBar";
import Legal from "./pages/Legal/Legal";
import GeneralCondition from "./pages/GeneralCondition/GeneralCondition";
import CTASection from "./components/Blocks/CTASection/CTASection";
import FAQSection from "./components/Blocks/FAQSection/FAQSection";
import FooterSection from "./components/Blocks/FooterSection/FooterSection";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/legal",
    element: <Legal />,
  },
  {
    path: "/termsOfSales",
    element: <GeneralCondition />,
  },
]);

const App: React.FC = () => {
  return (
    <BaseLayout>
      <RouterProvider router={router} />
    </BaseLayout>
  );
};

export default App;

const BaseLayout = ({ children }: { children: any }) => {
  return (
    <React.Fragment>
      {children}
      <CTASection />
      <FAQSection />
      <FooterSection />

      <NavBar />
    </React.Fragment>
  );
};
