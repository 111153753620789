/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import HeroSectionContent from "./HeroSectionContent";
import HeroSectionImage from "./HeroSectionImage";

import "./HeroSection.scss";

const HeroSection: React.FC = () => {
  return (
    <section
      id="hero"
      data-w-id="f30b8b36-a05a-9967-0f72-932d1f54e870"
      className="section-hero"
    >
      <div className="container hero-container">
        <img
          src="images/backgrounds/mainHeroGridPattern.png"
          alt=""
          style={{
            objectFit: "cover",
            position: "absolute",
            top: "0",
            right: "0",
            width: "70%",
            height: "90%",
            zIndex: 0,
          }}
        />

        <HeroSectionContent />
        <HeroSectionImage />
      </div>
    </section>
  );
};

export default HeroSection;
