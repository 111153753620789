import React from "react";
import LegalSections from "./LegalSections";

const Legal: React.FC = () => {
  return (
    <React.Fragment>
      <LegalSections />
    </React.Fragment>
  );
};

export default Legal;
