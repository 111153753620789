"use client";

import IconUserCircleuserSVG from "./SVGS/iconUserCircleuser";

const LoginButton = () => {
  return (
    <a
      data-w-id="d11884c0-84d9-6f28-5f1d-e0a1b48276f8"
      href="https://www.minuit.app/"
      className="button button--outline nav w-inline-block"
    >
      <IconUserCircleuserSVG className="image-14" />
      <div className="button-overflow nav">
        <div className="button-text">Espace client</div>
        <div className="button-text-absolute">Espace client</div>
      </div>
    </a>
  );
};

export default LoginButton;
