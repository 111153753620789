const iconList = [
  { src: "images/_iconiOS.svg", alt: "iOS", text: "iOS" },
  { src: "images/_iconAndroid.svg", alt: "Android", text: "Android" },
  { src: "images/_iconWeb.svg", alt: "Web", text: "Web" },
];

const BentoCardTechnologies = () => {
  return (
    <div className="bento__row--flex">
      <div className="bento__card--flex">
        <div className="bento__left-content">
          <h3 className="bento-cad__title title--wide-copy">
            Toujours une longueur d'avance
          </h3>
          <p className="paragraph-2-copy">
            Nous développons en interne des outils de création haut de gamme
            pour permettre la conception d'expérience utilisateur hors du commun
            pour nos clients.
          </p>
          <div className="icon-list-wrapper">
            {iconList.map((icon, index) => (
              <div className="icon-list__item" key={index}>
                <img loading="lazy" src={icon.src} alt={icon.alt} />
                <div className="text-block-9">{icon.text}</div>
              </div>
            ))}
          </div>
        </div>
        <div
          id="w-node-_36b5b5e8-ac7a-53bb-6da1-ce0eece3318b-df22aa20"
          data-w-id="36b5b5e8-ac7a-53bb-6da1-ce0eece3318b"
          className="bento__right-content"
        >
          <div className="layer-blur"></div>
          <img
            src="images/Figma.png"
            loading="lazy"
            style={{
              WebkitTransform:
                "translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              MozTransform:
                "translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              transform:
                "translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            }}
            alt=""
            className="image-31"
          />
          <img
            src="images/Element.png"
            loading="lazy"
            style={{
              WebkitTransform:
                "translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              MozTransform:
                "translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              transform:
                "translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            }}
            alt=""
            className="image-30"
          />
          <img
            id="w-node-_36b5b5e8-ac7a-53bb-6da1-ce0eece3318d-df22aa20"
            sizes="(max-width: 479px) 100vw, (max-width: 767px) 86vw, 100vw"
            alt=""
            src="images/design_window.png"
            loading="lazy"
            srcSet="images/design_window-p-500.png 500w, images/design_window-p-800.png 800w, images/design_window-p-1080.png 1080w, images/design_window.png 1252w"
            className="image-mobile desktop-hidden"
          />
          <img
            sizes="(max-width: 767px) 100vw, (max-width: 991px) 36vw, (max-width: 1439px) 37vw, (max-width: 1919px) 34vw, 494px"
            srcSet="images/laptop-design-2-p-500.png 500w, images/laptop-design-2-p-800.png 800w, images/laptop-design-2-p-1080.png 1080w, images/laptop-design-2-p-1600.png 1600w, images/laptop-design-2.png 1966w"
            alt=""
            src="images/laptop-design-2.png"
            loading="lazy"
            className="image-desktop mobile-hidden"
          />
        </div>
      </div>
    </div>
  );
};

export default BentoCardTechnologies;
