import React from "react";
import BentoSectionContent from "./BentoSectionContent";

const BentoSection: React.FC = () => {
  return (
    <>
      <div className="gradient-background mobile-hidden">
        <img
          loading="lazy"
          src="images/bento-gradient-2.svg"
          alt=""
          className="bento-gradient"
        />
      </div>
      <section id="presentation" className="section-bento">
        <div className="bento-container">
          <div className="bento__header">
            <h2 className="bento__heading">Un concept unique</h2>
            <p className="bento__text">
              Que vous ayez besoin de concevoir une première version pour
              obtenir un financement, de repenser votre produit pour améliorer
              ses performances ou faire croître plus rapidement une équipe de
              conception à distance, nous sommes là pour vous aider.
            </p>
          </div>
          <BentoSectionContent />
        </div>
      </section>
    </>
  );
};

export default BentoSection;
