"use client";

import React, { useEffect, useRef, useState } from "react";

type Props = {
  value?: number;
  label?: string;
  labelClassName?: string;
  valueClassName?: string;
  duration?: number; // Durée de l'animation en millisecondes
  start?: number; // Valeur de départ du compteur
  end?: number; // Valeur de fin du compteur, si différente de "value"
  operand?: string;
  suffix?: string;
};

const KPIItem: React.FC<Props> = ({
  value = 0,
  label,
  labelClassName,
  valueClassName,
  duration = 1000,
  start = 0,
  end,
  operand,
  suffix,
}) => {
  const [displayValue, setDisplayValue] = useState(start);
  const [hasAnimated, setHasAnimated] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const target = containerRef.current;
    if (!target) return;

    const targetValue = end !== undefined ? end : value;
    let observer: IntersectionObserver | null = null;

    const startAnimation = () => {
      const startTime = performance.now();

      const animate = (currentTime: number) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const currentNumber = Math.floor(
          start + (targetValue - start) * progress
        );
        setDisplayValue(currentNumber);

        if (progress < 1) {
          requestAnimationFrame(animate);
        } else {
          setHasAnimated(true);
          if (observer && target) {
            observer.unobserve(target);
          }
        }
      };

      requestAnimationFrame(animate);
    };

    observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          startAnimation();
        }
      },
      { threshold: 0.1 }
    );

    observer.observe(target);

    return () => {
      if (observer && target) {
        observer.unobserve(target);
      }
    };
  }, [start, value, end, duration, hasAnimated]);

  return (
    <div className="kpi__item" ref={containerRef}>
      <div className="kpi__number">
        {operand && <div>{operand}</div>}
        <div className={valueClassName}>{displayValue}</div>
        {suffix && <div>{suffix}</div>}
      </div>
      {label && <div className={`kpi__text ${labelClassName}`}>{label}</div>}
    </div>
  );
};

export default KPIItem;
