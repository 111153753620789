import React from "react";

// declare props type

type Props = {
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
};

const NavBarMenuButton: React.FC<Props> = ({ isMenuOpen, setIsMenuOpen }) => {
  return (
    <div
      onClick={() => setIsMenuOpen(!isMenuOpen)}
      data-w-id="d11884c0-84d9-6f28-5f1d-e0a1b48276ff"
      className="menu-button w-nav-button"
    >
      {isMenuOpen ? (
        <img
          loading="lazy"
          src="images/arrow-back.svg"
          alt=""
          className="icon-back"
        />
      ) : (
        <img
          loading="lazy"
          src="images/icon-hamburger.svg"
          alt=""
          className="icon-menu"
        />
      )}
    </div>
  );
};

export default NavBarMenuButton;
