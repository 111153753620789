"use client";

import React from "react";

import HeroSection from "./sections/HeroSection/HeroSection";
import BentoSection from "./sections/BentoSection/BentoSection";
import KPISection from "./sections/KPISection/KPISection";
import ProjectsSection from "./sections/ProjectsSection/ProjectsSection";
import AppSection from "./sections/AppSection/AppSection";
import TestimonialSection from "./sections/TestimonialSection/TestimonialSection";

const Home: React.FC = () => {
  return (
    <React.Fragment>
      <HeroSection />
      <BentoSection />
      <KPISection />
      <ProjectsSection />
      <AppSection />
      <TestimonialSection />
    </React.Fragment>
  );
};

export default Home;
