import React from "react";
import BentoCardTeamMembers from "./BentoCardTeamMembers";
import BentoCardChatWithUs from "./BentoCardChatWithUs";
import BentoCardTracking from "./BentoCardTracking";

const BentoCardTeam: React.FC = () => {
  return (
    <div className="bento__row">
      <BentoCardTeamMembers />
      <BentoCardTracking />
      <BentoCardChatWithUs />
    </div>
  );
};

export default BentoCardTeam;
