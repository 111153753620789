import React from "react";

type Props = {
  src?: string;
  alt?: string;
  className?: string;
};

const ImageUser: React.FC<Props> = ({ src, alt, className }) => {
  return (
    <img
      src={src!}
      loading="lazy"
      alt={alt ?? ""}
      className={`image-user ${className}`}
      width={50}
      height={50}
    />
  );
};

export default ImageUser;
