import React from "react";

interface EachProps {
  render: (item: any, index: number) => any;
  of?: any[];
}

const Each: React.FC<EachProps> = ({ render, of }) => {
  return React.Children.toArray(
    of?.map((item, index) => render(item, index))
  ) as any;
};

export default Each;
