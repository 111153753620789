import CTASectionHeader from "./CTASectionHeader";
import CTASectionMobile from "./CTASectionMobile";
import CTASectionPhone from "./CTASectionPhone";

const CTASection = () => {
  return (
    <section className="section-cta">
      <div className="cta-container">
        <CTASectionHeader />
        <img
          loading="lazy"
          src="images/line-gradient-pink.svg"
          alt=""
          className="cta__element-top"
        />
        <CTASectionPhone />
        <CTASectionMobile />
      </div>
    </section>
  );
};

export default CTASection;
