import React, { useState } from "react";
import NavDrawer from "./NavDrawer";
import NavBarLeftContent from "./NavBarLeftContent";
import NavBarRightContent from "./NavBarRightContent";
import NavBarMenuButton from "./NavBarMenuButton";

import "./NavBar.scss";

const NavBar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="nav">
      <div
        data-animation="over-left"
        data-collapse="medium"
        data-duration="200"
        data-easing="ease-out"
        data-easing2="ease-in"
        data-doc-height="1"
        role="banner"
        className="navbar w-nav"
      >
        <div className="nav-wrapper nav-container w-container">
          <NavBarLeftContent />
          <NavBarRightContent />
          <NavBarMenuButton
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        </div>
      </div>

      {isMenuOpen && <NavDrawer setIsMenuOpen={setIsMenuOpen} />}
    </div>
  );
};

export default NavBar;
