import React from "react";
import { onBookMeeting } from "../../../helpers";

const FooterQuickLinks: React.FC = () => {
  return (
    <div
      id="w-node-f3753720-db37-93a5-ee1d-e81def63d477-ef63d466"
      className="footer__column"
    >
      <h4 className="footer__column-heading">Liens rapides</h4>
      <div className="link-list">
        <a
          href="https://api.whatsapp.com/send/?phone=33651581106"
          className="link__item"
        >
          Contact
        </a>
        <a onClick={onBookMeeting} className="link__item">
          Prendre rendez-vous
        </a>
      </div>
    </div>
  );
};

export default FooterQuickLinks;
