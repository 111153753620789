import React from "react";
import AppSectionContent from "./AppSectionContent";
import AppSectionRightContent from "./AppSectionRightContent";

const AppSection = () => {
  return (
    <section className="section-app">
      <div className="app-container">
        <AppSectionContent />
        <AppSectionRightContent />
      </div>
    </section>
  );
};

export default AppSection;
