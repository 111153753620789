/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

const FooterLegal: React.FC = () => {
  const links = [
    { href: "/legal", text: "Mentions légales" },
    { href: "/termsOfSales", text: "Conditions générales de vente" },
    // { href: "#", text: "Politique de confidentialité" },
  ];

  return (
    <div
      id="w-node-f3753720-db37-93a5-ee1d-e81def63d46d-ef63d466"
      className="footer__column"
    >
      <h4 className="footer__column-heading">Légal</h4>
      <div className="link-list">
        {links.map((link, index) => (
          <a key={index} href={link.href} className="link__item">
            {link.text}
          </a>
        ))}
      </div>
    </div>
  );
};

export default FooterLegal;
