import React from "react";

const BentoCardTracking: React.FC = () => {
  return (
    <div
      id="w-node-_36b5b5e8-ac7a-53bb-6da1-ce0eece33169-df22aa20"
      className="bento__card bento__card--big glowing_border"
    >
      <div className="overlay"></div>
      <img loading="lazy" src="images/minuitIcon.svg" alt="" />
      <img
        sizes="(max-width: 767px) 100vw, (max-width: 991px) 57vw, (max-width: 1279px) 52vw, (max-width: 1439px) 53vw, (max-width: 1919px) 50vw, 720.5px"
        srcSet="images/ipad-home-1-p-500.png 500w, images/ipad-home-1-p-800.png 800w, images/ipad-home-1-p-1080.png 1080w, images/ipad-home-1-p-1600.png 1600w, images/ipad-home-1-p-2000.png 2000w, images/ipad-home-1-p-2600.png 2600w, images/ipad-home-1.png 2892w"
        alt=""
        src="images/ipad-home-1.png"
        loading="lazy"
        className="image-3"
      />
      <img
        src="images/img-notification-v1.png"
        loading="lazy"
        sizes="100vw"
        srcSet="images/img-notification-v1-p-500.png 500w, images/img-notification-v1-p-800.png 800w, images/img-notification-v1-p-1080.png 1080w, images/img-notification-v1.png 1448w"
        alt=""
        className="notification-image _1"
      />
      <img
        src="images/img-notification-v2.png"
        loading="lazy"
        sizes="100vw"
        srcSet="images/img-notification-v2-p-500.png 500w, images/img-notification-v2-p-800.png 800w, images/img-notification-v2-p-1080.png 1080w, images/img-notification-v2.png 1448w"
        alt=""
        className="notification-image _2"
      />
      <img
        src="images/Flat-iPhone.png"
        loading="lazy"
        sizes="(max-width: 767px) 100vw, 200px"
        srcSet="images/Flat-iPhone-p-500.png 500w, images/Flat-iPhone-p-800.png 800w, images/Flat-iPhone.png 957w"
        alt=""
        className="phone-image"
      />
      <div className="bento__card-text">
        <h3 className="bento-cad__title-copy">Suivi en temps réel</h3>
        <p className="paragraph-2">
          Suivez les avancées de votre projet en temps réel où que vous soyez!
        </p>
      </div>
    </div>
  );
};

export default BentoCardTracking;
