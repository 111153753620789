/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import FooterQuickLinks from "./FooterQuickLinks";
import FooterLegal from "./FooterLegal";
import FooterAgency from "./FooterAgency";
import FooterCopyright from "./FooterCopyright";

const FooterSection: React.FC = () => {
  return (
    <section className="footer">
      <div className="footer-container">
        <div
          id="w-node-f3753720-db37-93a5-ee1d-e81def63d468-ef63d466"
          className="footer-columns"
        >
          <FooterAgency />
          <FooterLegal />
          <FooterQuickLinks />
        </div>

        <div className="footer-bottom">
          <FooterCopyright />
        </div>
      </div>
      <img
        loading="lazy"
        src="images/meshGradientBottom.svg"
        alt=""
        className="bottom-vector"
      ></img>
    </section>
  );
};

export default FooterSection;
