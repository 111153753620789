import React from "react";

const FooterAgency: React.FC = () => {
  return (
    <div
      id="w-node-f3753720-db37-93a5-ee1d-e81def63d469-ef63d466"
      className="footer__column"
    >
      <img
        loading="lazy"
        src="https://cdn.prod.website-files.com/66db3c89ac4a3261f8ccd277/66db4cae973c018d1d3b7077_logoMain.svg"
        alt=""
        className="brand"
      />
      <a
        href="https://apps.apple.com/fr/app/minuit-app/id1661696886"
        target="_blank"
        className="download-button w-inline-block"
        rel="noreferrer"
      >
        <img
          loading="lazy"
          src="images/App-store.svg"
          alt=""
          className="download-app"
        />
      </a>
    </div>
  );
};

export default FooterAgency;
