import React from "react";

type Props = {
  title?: string;
  description?: string;
  onPress?: () => void;
  isOpen?: boolean;
};

const FAQItem: React.FC<Props> = ({ title, description, onPress, isOpen }) => {
  return (
    <div role="listitem" className="w-dyn-item" onClick={onPress}>
      <div
        data-w-id="fe2c33c4-3c14-af47-4715-0d33155962d9"
        className={`faq__item${isOpen ? " open" : ""}`}
      >
        <div className="faq__question">
          <h4>{title}</h4>
          <div className="icon-wrapper">
            <div className="icon-vector-1"></div>
            <div className="icon-vector-2"></div>
          </div>
        </div>
        <div className="faq__answer">
          <p className="paragraph-7">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FAQItem);
