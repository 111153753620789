import React from "react";
import IconInstagramSVG from "./SVGS/iconInstagram";

const FollowInstagram = () => {
  return (
    <a
      href="https://www.instagram.com/minuit.agency"
      target="_blank"
      className="button button--no-outline w-inline-block"
      rel="noreferrer"
    >
      <IconInstagramSVG />
      <div>Suivez-nous sur Instagram</div>
    </a>
  );
};

export default FollowInstagram;
