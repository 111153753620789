import React from "react";

const EmptyState = () => {
  return (
    <div className="w-dyn-empty">
      <div>No items found.</div>
    </div>
  );
};

export default EmptyState;
