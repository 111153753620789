import "./HeroSectionImage.scss";

const HeroSectionImage = () => {
  const transformStyleFull = {
    WebkitTransform:
      "translate3d(0%, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    MozTransform:
      "translate3d(0%, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    transform:
      "translate3d(0%, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
  };

  const transformStyleVertical = {
    WebkitTransform:
      "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    MozTransform:
      "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    transform:
      "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
  };

  const frames = [
    {
      id: null,
      frameClass: "frame-image frame-image--first",
      figmaCursorSrc: "images/figmaCursors/thomas.png",
      figmaCursorClass: "figma-cursor-1 cursor",
      figmaCursorStyle: transformStyleFull,
      phoneSrc: "images/frame1.png",
      phoneSrcSet: "images/frame1-p-500.png 500w, images/frame1.png 540w",
      phoneSizes:
        "(max-width: 767px) 100vw, (max-width: 991px) 20vw, (max-width: 1279px) 17vw, (max-width: 1439px) 182.84796142578125px, (max-width: 1919px) 14vw, 261.21142578125px",
      phoneClass: "image-26 phone",
    },
    {
      id: "w-node-f30b8b36-a05a-9967-0f72-932d1f54e88f-df22aa20",
      frameClass: "frame-image frame-image--second",
      figmaCursorSrc: "images/figmaCursors/theo.png",
      figmaCursorClass: "figma-cursor-3 cursor",
      figmaCursorStyle: transformStyleVertical,
      phoneSrc: "images/frame2.png",
      phoneSrcSet: "images/frame2-p-500.png 500w, images/frame2.png 538w",
      phoneSizes:
        "(max-width: 767px) 100vw, (max-width: 991px) 31vw, (max-width: 1279px) 26vw, (max-width: 1439px) 305.88653564453125px, (max-width: 1919px) 325.0030517578125px, 20vw",
      phoneClass: "image-25 phone",
    },
    {
      id: "w-node-f30b8b36-a05a-9967-0f72-932d1f54e892-df22aa20",
      frameClass: "frame-image frame-image--third",
      figmaCursorSrc: "images/figmaCursors/marine.png",
      figmaCursorClass: "figma-cursor-2 cursor",
      figmaCursorStyle: transformStyleVertical,
      phoneSrc: "images/frame3.png",
      phoneSrcSet:
        "images/frame3-p-500.png 500w, images/frame3-p-800.png 800w, images/frame3-p-1080.png 1080w, images/frame3.png 1776w",
      phoneSizes:
        "(max-width: 767px) 100vw, (max-width: 991px) 38vw, (max-width: 1279px) 42vw, (max-width: 1439px) 552.6981811523438px, (max-width: 1919px) 44vw, 740.85107421875px",
      phoneClass: "image-24 phone",
    },
  ];

  return (
    <div className="heroimage animate">
      <div className="cursor-container"></div>
      <div className="frames mobile-hidden">
        {frames.map((frame, index) => (
          <div
            key={index}
            id={frame.id || undefined}
            className={frame.frameClass}
          >
            <img
              width="77.5"
              style={frame.figmaCursorStyle}
              alt=""
              src={frame.figmaCursorSrc}
              loading="lazy"
              className={frame.figmaCursorClass}
            />
            <img
              sizes={frame.phoneSizes}
              srcSet={frame.phoneSrcSet}
              alt=""
              src={frame.phoneSrc}
              loading="lazy"
              className={frame.phoneClass}
            />
          </div>
        ))}
      </div>

      <img
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        alt="Projets réalisés par l'agence minuit.agency"
        src="images/Frame-48095926.png"
        className="desktop-hidden"
      />

      <div className="desktop-hidden-cursor">
        {[
          {
            src: "images/figmaCursors/thomas.png",
            class: "figma-cursor-1 cursor mobile",
          },
          {
            src: "images/figmaCursors/theo.png",
            class: "figma-cursor-3 cursor mobile",
          },
          {
            src: "images/figmaCursors/marine.png",
            class: "figma-cursor-2 cursor mobile",
          },
        ].map((cursor, i) => (
          <img
            key={i}
            alt="Curseur équipe de design"
            src={cursor.src}
            className={cursor.class}
            style={{
              width: "77.5px",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default HeroSectionImage;
