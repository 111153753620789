import React from "react";
import { onBookMeeting } from "../../../helpers";

interface NavDrawerProps {
  setIsMenuOpen: (isOpen: boolean) => void;
}

const menuItems = [
  { href: "/#presentation", text: "Présentation" },
  { href: "/#realisations", text: "Réalisations" },
  { href: "/#avis", text: "Avis" },
  { href: "/#faqs", text: "FAQ" },
];

const NavDrawer: React.FC<NavDrawerProps> = ({ setIsMenuOpen }) => {
  const handleItemClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="nav-drawer">
      <div className="drawer-container">
        {menuItems.map((item) => (
          <a
            key={item.href}
            href={item.href}
            className="nav-link-full"
            onClick={handleItemClick}
          >
            {item.text}
          </a>
        ))}
        <div className="w-layout-vflex btn-wrapper">
          <a
            onClick={() => {
              onBookMeeting();
              handleItemClick();
            }}
            target="_blank"
            className="button button--sm-padding w-inline-block"
            rel="noreferrer"
          >
            <div className="button-text">Prendre rendez-vous</div>
            <img
              loading="lazy"
              src="images/memojiBookCall.png"
              alt=""
              className="button-image"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavDrawer;
