import React from "react";
import LoginButton from "../../../components/LoginButton";
import MakeAppointmentButton from "../../../components/MakeAppointmentButton";

const NavBarRightContent: React.FC = () => {
  return (
    <div className="right-content">
      <div className="button-group">
        <LoginButton />
        <MakeAppointmentButton containerClass="mobile-hidden" />
      </div>
    </div>
  );
};

export default NavBarRightContent;
