import React from "react";
import InstagramSVG from "../../../components/SVGS/instagram";

const FooterCopyright: React.FC = () => {
  return (
    <div className="copyright-wrapper">
      <p className="copyright-text">
        © 2024 minuit.agency - Tous droits réservés
      </p>
      <a
        data-w-id="f3753720-db37-93a5-ee1d-e81def63d485"
        href="https://instagram.com/minuit.agency"
        target="_blank"
        className="icon-container w-inline-block"
        rel="noreferrer"
      >
        <div className="div-block-8">
          <div className="code-embed w-embed">
            <svg
              aria-hidden="true"
              role="img"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="100%"
              height="100%"
            >
              <path
                fillRule="evenodd"
                d="M7.986 2.398h8.028c3.073 0 5.586 2.447 5.586 5.437v8.326c0 2.99-2.513 5.437-5.586 5.437H7.986c-3.071 0-5.586-2.447-5.586-5.437V7.835c0-2.99 2.515-5.437 5.586-5.437Zm-2.9 16.59a4.143 4.143 0 0 0 2.9 1.176h8.03a4.142 4.142 0 0 0 2.901-1.176 3.922 3.922 0 0 0 1.209-2.825V7.836c0-1.061-.43-2.064-1.209-2.823a4.144 4.144 0 0 0-2.902-1.178H7.987c-1.092 0-2.12.42-2.901 1.178a3.923 3.923 0 0 0-1.21 2.823v8.327c.001 1.062.431 2.067 1.21 2.825ZM16.149 6.7c0-.674.561-1.22 1.252-1.22.692 0 1.252.546 1.252 1.22 0 .673-.56 1.216-1.252 1.216-.69 0-1.252-.543-1.252-1.216ZM6.74 11.925c0-2.842 2.367-5.146 5.286-5.146 2.92 0 5.287 2.304 5.287 5.146s-2.368 5.146-5.287 5.146-5.286-2.304-5.286-5.146Zm1.8.003c0 1.861 1.55 3.371 3.463 3.371 1.91 0 3.46-1.51 3.46-3.371 0-1.86-1.55-3.368-3.46-3.368-1.913 0-3.463 1.508-3.463 3.368Z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <div className="code-embed-absolute w-embed">
            <svg
              aria-hidden="true"
              role="img"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="100%"
              height="100%"
            >
              <path
                fillRule="evenodd"
                d="M7.986 2.398h8.028c3.073 0 5.586 2.447 5.586 5.437v8.326c0 2.99-2.513 5.437-5.586 5.437H7.986c-3.071 0-5.586-2.447-5.586-5.437V7.835c0-2.99 2.515-5.437 5.586-5.437Zm-2.9 16.59a4.143 4.143 0 0 0 2.9 1.176h8.03a4.142 4.142 0 0 0 2.901-1.176 3.922 3.922 0 0 0 1.209-2.825V7.836c0-1.061-.43-2.064-1.209-2.823a4.144 4.144 0 0 0-2.902-1.178H7.987c-1.092 0-2.12.42-2.901 1.178a3.923 3.923 0 0 0-1.21 2.823v8.327c.001 1.062.431 2.067 1.21 2.825ZM16.149 6.7c0-.674.561-1.22 1.252-1.22.692 0 1.252.546 1.252 1.22 0 .673-.56 1.216-1.252 1.216-.69 0-1.252-.543-1.252-1.216ZM6.74 11.925c0-2.842 2.367-5.146 5.286-5.146 2.92 0 5.287 2.304 5.287 5.146s-2.368 5.146-5.287 5.146-5.286-2.304-5.286-5.146Zm1.8.003c0 1.861 1.55 3.371 3.463 3.371 1.91 0 3.46-1.51 3.46-3.371 0-1.86-1.55-3.368-3.46-3.368-1.913 0-3.463 1.508-3.463 3.368Z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
        <img
          src="images/64928a7654274f9c463de498_bg-Instagram.svg"
          loading="lazy"
          alt=""
          className="image-29"
        />
      </a>
    </div>
  );
};

export default FooterCopyright;
