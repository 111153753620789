import React from "react";

import "./GeneralConditionSection.scss";

const GeneralConditionSection: React.FC = () => {
  return (
    <section className="section-container">
      <div className="container">
        <h1 className="heading-4">Conditions générales de vente</h1>
        <h2 className="article__heading">Préambule</h2>
        <p className="paragraph">
          Conditions générales de vente de prestations de services entre
          professionnels sur internet
          <br />
          <br />
          Les conditions générales de vente décrites ci-après détaillent les
          droits et obligations de minuit.agency SASU (SIRET: 91928949600011,
          immatriculée au RCS de Bordeaux sous le numéro 919 289 496 et ayant
          son siège social au 44 rue Minvielle, 33000, Bordeaux) et de son
          client dans le cadre de la vente de ses prestations de services. Toute
          prestation accomplie par minuit.agency SASU implique donc
          l&#x27;adhésion sans réserve de l&#x27;acheteur aux présentes
          conditions générales de vente.
        </p>
        <h2 className="article__heading">Article 1 - Principes</h2>
        <p className="paragraph">
          Ces conditions générales concernent les prestations de services
          fournis entre professionnels (prestataire / acheteur). Les présentes
          conditions générales expriment l’intégralité des obligations des
          parties. Elles constituent le socle unique de la relation commerciale
          entre les parties, et, en ce sens, l’acheteur est réputé les accepter
          sans réserve. Les présentes conditions générales de vente prévalent
          sur tout autre document, et notamment sur toutes conditions générales
          d’achat. Elles s’appliquent, sans restriction ni réserve, à tous les
          services rendus par le prestataire auprès des acheteurs de même
          catégorie. Le prestataire et l’acheteur conviennent que les présentes
          conditions générales régissent exclusivement leur relation. Le
          prestataire se réserve le droit de modifier ponctuellement ses
          conditions générales. Elles seront applicables dès leur mise en ligne.
          Si une condition de prestation de services venait à faire défaut, elle
          serait considérée être régie par les usages en vigueur dans le secteur
          de la vente à distance dont les sociétés ont leur siège en France. Les
          présentes conditions générales de vente sont communiquées à tout
          acheteur qui en fait la demande, afin de lui permettre de passer
          commande. Le fournisseur se réserve le droit de déroger à certaines
          clauses des présentes, en fonction des négociations menées avec
          l’acheteur, par l’établissement de conditions de vente particulières.
          Le prestataire peut, en outre, établir des conditions générales de
          vente catégorielles, dérogatoires aux présentes conditions générales
          de vente, en fonction du type d’acheteur considéré, selon des critères
          qui resteront objectifs.
        </p>
        <h2 className="article__heading">Article 2 - Contenu</h2>
        <p className="paragraph">
          Les présentes conditions générales ont pour objet de définir les
          droits et obligations des parties dans le cadre de la vente en ligne
          des prestations proposées par le prestataire à l&#x27;acheteur. Elles
          concernent les services suivants : le design et le développement
          d‘applications mobiles et web.
          <br />
          <br />
          Les présentes conditions ne concernent que les prestations effectuées
          en France pour des acheteurs situés sur le territoire français. Pour
          toute prestation effectuée hors de France, ou pour un acheteur situé
          hors de France, il convient de le signaler pour obtenir un devis
          spécifique.
        </p>
        <h2 className="article__heading">Article 3 - La commande</h2>
        <p className="paragraph">
          L&#x27;acheteur peut passer sa commande via l&#x27;application mobile
          &quot;minuit.app&quot;, en ligne sur le site web
          &quot;minuit.agency&quot;, ou en sollicitant un devis directement
          auprès de la société. Dans tous les cas, le client recevra par mail un
          bon de commande confirmant les détails.
          <br />
          <br />
          Toute commande, quelle que soit son origine, vaut acceptation des prix
          et descriptions des prestations proposées. Le prestataire se réserve
          le droit de bloquer ou d&#x27;annuler une commande en cas de problème,
          notamment en cas de défaut de paiement, d&#x27;adresse erronée ou de
          tout autre problème sur le compte de l&#x27;acheteur, jusqu&#x27;à la
          résolution du problème.
          <br />
          <br />
          Pour toute question relative au suivi d&#x27;une commande,
          l&#x27;acheteur peut contacter le service client au numéro 05 31 60 09
          91, disponible du lundi au vendredi de 8h30 à 19h (coût d&#x27;un
          appel local). Alternativement, le client peut également contacter son
          chef de projet au numéro WhatsApp qui lui a été communiqué lors de la
          confirmation de la commande.
        </p>
        <h2 className="article__heading">Article 4 - Signature électronique</h2>
        <p className="paragraph">
          Le paiement du premier acompte et la validation finale de la commande
          valent preuve de l’accord de l’acheteur et constituent une signature
          électronique ayant, entre les parties, la même valeur juridique qu’une
          signature manuscrite, conformément à l’article 1366 du Code civil.
          Cela emporte :
          <br />
          <br />• Exigibilité des sommes dues au titre du bon de commande ;
          <br />
          <br />• Signature et acceptation expresse de toutes les opérations
          effectuées.
        </p>
        <h2 className="article__heading">
          Article 5 - Confirmation de commande
        </h2>
        <p className="paragraph">
          Les informations contractuelles feront l’objet d’une confirmation par
          voie d’e-mail avant le début de l’exécution des prestations, à
          l’adresse indiquée par l’acheteur au sein du bon de commande.
        </p>
        <h2 className="article__heading">
          Article 6 - Preuve de la transaction
        </h2>
        <p className="paragraph">
          Les registres informatisés, conservés dans les systèmes informatiques
          du prestataire dans des conditions raisonnables de sécurité, seront
          considérés comme les preuves des communications, des commandes et des
          paiements intervenus entre les parties. L&#x27;archivage des bons de
          commande et des factures est effectué sur un support fiable et durable
          pouvant être produit à titre de preuve.
        </p>
        <h2 className="article__heading">
          Article 7 - Informations sur les prestations
        </h2>
        <p className="paragraph">
          Les prestations régies par les présentes conditions générales
          comprennent la conception, le design, et le développement de solutions
          digitales sur mesure pour diverses plateformes telles que iOS,
          Android, web, TV et montres connectées. Ces prestations sont réalisées
          directement par MINUIT.AGENCY ou sous son contrôle strict,
          conformément aux descriptions fournies sur le site internet de la
          société et dans les limites de ses capacités opérationnelles.
          <br />
          <br />
          Personnel du prestataire
          <br />‍<br />
          En vertu de l&#x27;autorité hiérarchique et disciplinaire qu&#x27;il
          exerce à titre exclusif sur son personnel, celui-ci restera placé sous
          le contrôle effectif du prestataire durant la complète exécution des
          prestations. En cas d&#x27;intervention dans les locaux de
          l&#x27;acheteur, le prestataire s&#x27;engage à respecter les
          obligations en matière d&#x27;hygiène et de sécurité dont
          l&#x27;acheteur lui communiquera la teneur, sous réserve que son
          personnel se voit accorder une protection identique à celle que
          accordée aux employés du prestataire. Le prestataire garantit la
          régularité de la situation de son personnel au regard des articles L.
          1221-10 et suivants et L. 3246-1 et suivants du code du travail. Le
          prestataire certifie, en outre, être en conformité avec les
          dispositions des articles L. 8221-1 et L. 8221-2 du code du travail,
          relatifs à la lutte contre le travail dissimulé, ainsi qu&#x27;avec
          les dispositions du Livre III, Titre IV du code du travail.
        </p>
        <h2 className="article__heading">Article 8 - Prix</h2>
        <p className="paragraph">
          Calcul
          <br />
          <br />
          Sauf convention contraire, le prix des prestations est établi en
          fonction du nombre et de l’expérience du personnel requis, du niveau
          de compétence et de responsabilité nécessaire ; les taux horaires sont
          révisés périodiquement.
          <br />
          <br />
          Seront également facturés, s’il y a lieu, les frais de déplacement, de
          subsistance et d’hébergement engagés pour l’exécution des prestations.
          La TVA au taux en vigueur s’ajoute, le cas échéant, aux honoraires et
          débours. Les retards ou autres problèmes imprévus, dont le prestataire
          n’a pas la maîtrise et qui échappent à son contrôle, peuvent entraîner
          des honoraires supplémentaires susceptibles de faire l’objet d’une
          facturation complémentaire. Le prestataire s’engage à informer
          l’acheteur de ces retards et/ou problèmes dès qu’ils surviendront afin
          de pouvoir en évaluer les conséquences avec lui.
          <br />
          <br />
          Des factures seront émises correspondant aux prestations fournies et
          aux débours engagés par provision et au fur et à mesure de leur
          réalisation. Sauf convention contraire, les factures sont émises
          chaque mois. Le règlement des factures est exigible dès réception.
          <br />
          <br />
          Pénalités de retard
          <br />
          <br />
          Conformément à la loi, tout retard de paiement oblige le prestataire à
          facturer des pénalités de retard. Le taux des pénalités de retard est
          égal à trois fois le taux d’intérêt légal.
          <br />
          <br />
          Par ailleurs, le débiteur en retard sera tenu de régler au prestataire
          une indemnité forfaitaire pour frais de recouvrement de 40 euros,
          conformément à l’article D441-5 du Code de commerce.
          <br />‍<br />
          Au surplus, le prestataire sera fondé à suspendre l’exécution des
          prestations jusqu’à règlement complet de la facture impayée sans que
          cette inexécution puisse être considérée comme lui étant imputable.
          <br />
          <br />
          Acompte
          <br />
          <br />
          Toute commande, telle que définie ci-dessus, donne lieu au versement
          de trois acomptes calculés selon les modalités définies ci-dessous :
          <br />
          <br />• Un premier acompte de 40 % du montant total estimé du projet à
          la signature du contrat.
          <br />• Un second acompte de 30 % du montant total du projet à la
          validation du design par le client.
          <br />• Le solde de 30 % du montant total du projet à la livraison de
          la recette.
          <br />
          <br />
          Hors cas de force majeure, toute annulation de la commande par
          l’acheteur au-delà d’un délai de deux jours après la date de la
          commande ne pourra donner lieu au remboursement de ces acomptes déjà
          versés.
          <br />
          <br />
          Escompte
          <br />
          <br />
          En cas de règlement par l’acheteur avant la date de paiement figurant
          sur la facture ou dans un délai inférieur à celui mentionné aux
          présentes conditions générales de vente, un escompte de 2 % sera
          accordé sur le montant HT de la facture. Pour en bénéficier, le
          paiement anticipé doit intervenir dans un délai de 10 jours à compter
          de la date d’émission de la facture.
        </p>
        <h2 className="article__heading">
          Article 9 - Modalités et délais de paiement
        </h2>
        <p className="paragraph">
          Règlement
          <br />
          <br />
          Le règlement des tâches prévues au titre du devis se fait par virement
          sur le compte bancaire du prestataire. Le RIB du prestataire se trouve
          sur les factures d’acompte et les bons de commande.
          <br />
          <br />
          Pour les tâches non prévues au titre du devis, le paiement s’effectue
          directement depuis l’application “minuit.app” par tous les moyens de
          paiement prévus par notre prestataire Stripe (carte bleue, prélèvement
          SEPA, etc.). Le client a également la possibilité d’acheter des lots
          de “minuit.coin” depuis l’application pour bénéficier de tarifs
          préférentiels sur ces tâches. Le paiement de ces tâches non prévues
          est exigé en intégralité et en amont, sans acompte.
          <br />
          <br />
          Le prestataire se réserve le droit de refuser d’effectuer une
          livraison ou d’honorer une commande émanant d’un acheteur qui n’aurait
          pas réglé totalement ou partiellement une commande précédente, ou avec
          lequel un litige de paiement serait en cours d’administration.
          <br />
          <br />
          Le prestataire a mis en place une procédure de vérification des
          commandes destinée à assurer qu’aucune personne n’utilise les
          coordonnées bancaires d’une autre personne à son insu. Dans le cadre
          de cette vérification, il pourra être demandé à l’acheteur d’adresser,
          par voie sécurisée, une copie de documents attestant de son identité
          et de sa qualité de représentant légal de l’entreprise, conformément
          au RGPD.
          <br />
          <br />
          <br />
          Paiement à terme
          <br />
          <br />
          Le prix est payable conformément aux échéances définies dans l’article
          8 “Prix” des présentes conditions générales de vente.
          <br />
          Retard de paiement
          <br />
          <br />
          <br />
          Retard de paiement
          <br />
          <br />
          Tout retard de paiement entraînera l’exigibilité immédiate de la
          totalité des sommes dues au prestataire par l’acheteur, sans préjudice
          de toute autre action que le prestataire serait en droit d’intenter à
          ce titre contre l’acheteur.
          <br />‍<br />
          Modifications et retards dans l’avancée du devis principal
          <br />
          <br />
          Dans le cas où le client commande des prestations supplémentaires
          entraînant un retard dans l’avancée du devis principal, le prestataire
          se réserve le droit de demander le paiement anticipé du solde restant
          du devis principal. Cette demande n’exonère pas le prestataire de ses
          obligations contractuelles concernant la livraison et la qualité du
          projet selon les termes du devis. Cette mesure est justifiée par le
          besoin de maintenir une gestion financière équilibrée du projet et de
          respecter les échéances initialement convenues.
        </p>
        <h2 className="article__heading">Article 10 - Délais d’intervention</h2>
        <p className="paragraph">
          Sauf en cas de force majeure ou lors des périodes de fermeture
          clairement annoncées sur la page d’accueil du site, les délais
          d’intervention seront, dans la limite des disponibilités du
          prestataire, ceux indiqués ci-dessous. Les délais d’exécution courent
          à compter de la date d’enregistrement de la commande indiquée sur le
          mail de confirmation de la commande.
          <br />
          <br />
          Pour les prestations réalisées en France métropolitaine, le délai
          maximal d’exécution est de huit mois à compter du jour suivant celui
          où l’acheteur a passé sa commande. En cas de retard dans l’exécution
          des prestations, le prestataire s’engage à informer l’acheteur dès que
          possible et à lui indiquer la durée estimée du retard.
          <br />
          <br />
          La responsabilité du prestataire pourra être engagée en cas de retard
          imputable au prestataire, sous réserve des dispositions légales en
          vigueur. Toutefois, les éventuels dommages et intérêts seront limités
          conformément à l’article 18 des présentes conditions générales de
          vente.
          <br />
          <br />
          En cas d’indisponibilité du prestataire pour réaliser la prestation,
          l’acheteur en sera informé au plus tôt et aura la possibilité
          d’annuler sa commande. L’acheteur aura alors la possibilité de
          demander le remboursement des sommes versées dans les 30 jours au plus
          tard de leur versement.
        </p>
        <h2 className="article__heading">
          Article 11 - Modalités de réalisation
        </h2>
        <p className="paragraph">
          La réalisation n&#x27;est entamée qu&#x27;après confirmation du
          paiement par l&#x27;organisme bancaire du prestataire. Elle est
          fournie dans le délai prévu sur le bon de commande, à compter de la
          réception par le prestataire du bon de commande. En cas de non-respect
          des conditions de paiement figurant ci-dessus, le vendeur pourra
          suspendre ou annuler la prestation.
          <br />
          <br />
          Tout déplacement en pure perte du prestataire à cause d&#x27;une
          adresse erronée ou incomplète sera facturé à l&#x27;acheteur. La fin
          de la prestation donnera lieu à un document de fin
          d&#x27;intervention. L&#x27;acheteur doit indiquer sur ce document et
          sous forme de réserves manuscrites accompagnées de sa signature toute
          anomalie concernant la prestation ou les conditions de sa réalisation.
          Cette vérification est considérée comme effectuée dès lors que
          l&#x27;acheteur, ou une personne autorisée par lui, a signé le
          document de fin d&#x27;intervention.
        </p>
        <h2 className="article__heading">
          Article 12 - Obligations du prestataire
        </h2>
        <p className="paragraph">
          Les engagements du prestataire constituent une obligation de moyens au
          terme de laquelle les prestations seront exécutées dans le strict
          respect des règles professionnelles en usage ainsi, le cas échéant,
          que conformément aux conditions du contrat. Pour ce faire, le
          prestataire affectera à l&#x27;exécution des prestations les
          professionnels dotés des compétences requises pour assurer leur
          réalisation conformément à ses standards de qualité.
        </p>
        <h2 className="article__heading">
          Article 13 - Obligations de l’acheteur
        </h2>
        <p className="paragraph">
          Afin de faciliter la bonne exécution des prestations, l’acheteur
          s’engage à :<br />
          <br />• Fournir des informations et documents complets et exacts :
          L’acheteur doit fournir au prestataire toutes les informations et
          documents nécessaires, de manière complète et exacte, dans les délais
          convenus, sans que le prestataire ne soit tenu d’en vérifier la
          complétude ou l’exactitude.
          <br />
          <br />• Prendre des décisions rapidement : L’acheteur s’engage à
          prendre les décisions requises dans les délais impartis et à obtenir
          les approbations hiérarchiques nécessaires.
          <br />
          <br />• Désigner un interlocuteur décisionnaire : Un correspondant
          investi d’un pouvoir de décision doit être désigné par l’acheteur pour
          faciliter la communication et la prise de décision.
          <br />
          <br />• Assurer la disponibilité des interlocuteurs clés : L’acheteur
          doit veiller à ce que les interlocuteurs clés et le correspondant
          soient disponibles tout au long de l’exécution des prestations.
          <br />
          <br />• Signaler toute difficulté : Toute difficulté potentiellement
          affectant l’exécution des prestations doit être communiquée
          directement au prestataire par l’acheteur.
          <br />
          <br />• L’acheteur s’engage à tester les livrables sur un appareil de
          moins de trois ans, équipé de la dernière version possible de son
          système d’exploitation (iOS pour iPhone, Android pour les appareils
          Android, etc.).
          <br />
          <br />
          Les retours sur chaque version livrée doivent être effectués de
          préférence via l’application “minuit.app”. En cas d’indisponibilité ou
          de dysfonctionnement de l’application, le client pourra utiliser un
          autre moyen de communication convenu avec le prestataire, tel que
          l’e-mail ou une plateforme alternative.
          <br />
          <br />
          Tout retard dans le testing d’une version, ou le testing sur une
          version obsolète, pourra entraîner un report de la date de livraison
          finale du projet. Ce délai supplémentaire sera calculé à partir de la
          date prévue initialement pour le testing. Le prestataire s’engage
          toutefois à faire ses meilleurs efforts pour minimiser les impacts sur
          le planning initial.
          <br />
          <br />
          La responsabilité du prestataire ne pourra être engagée en cas
          d’incompatibilité des livrables avec des appareils ou systèmes
          d’exploitation non spécifiés par le client ou obsolètes. Le client est
          tenu d’assurer que les appareils utilisés pour tester les livrables
          sont conformes aux exigences spécifiées dans le contrat.
        </p>
        <h2 className="article__heading">
          Article 14 - Informations et publicité
        </h2>
        <p className="paragraph">
          L&#x27;acheteur reconnaît et accepte : que les parties pourront sauf
          demande expresse contraire de l&#x27;autre partie, correspondre ou
          transférer des documents par courrier électronique circulant sur le
          réseau internet ;<br />
          <br />
          qu&#x27;aucune des parties n&#x27;exerce de maîtrise sur la capacité,
          la fiabilité, l&#x27;accès ou la sécurité de ces courriers
          électroniques ;<br />
          <br />
          que le prestataire ne saura être tenu pour responsables de toute
          perte, dommage, frais ou préjudice occasionnés par la perte, le
          retard, l&#x27;interception, le détournement ou l&#x27;altération de
          tout courrier électronique causés par un fait quelconque. De façon
          générale, les parties s&#x27;engagent à respecter la réglementation
          applicable à la protection des données personnelles et notamment les
          dispositions de la loi 78-17 du 6 janvier 1978 relative à
          l&#x27;informatique, aux fichiers et aux libertés.
          <br />
          <br />
          <br />
          Informations confidentielles
          <br />
          <br />
          Chacune des parties s&#x27;engage à ne pas divulguer les informations
          confidentielles reçues de l&#x27;autre partie. Les informations
          confidentielles s&#x27;entendent des informations de toute nature,
          visuelles ou orales, sur quelque support que ce soit, relatives à la
          structure, l&#x27;organisation, les affaires, les politiques internes
          diverses, les projets et le personnel de chacune des parties. Sous
          réserve des exceptions visées ci-après, la présente obligation de
          confidentialité produira ses effets pendant la durée suivante : 5 ans
          suivant le terme des prestations. Ont également un caractère
          confidentiel, le contenu des prestations ainsi que les rapports,
          courriers, informations, notes, devis, fournis par le prestataire au
          cours de l&#x27;exécution des prestations. Ces documents sont
          communiqués à l&#x27;acheteur pour un usage strictement interne et à
          la condition de ne pas les divulguer à des tiers ni de les annexer à
          un document qu&#x27;il serait amené à produire.
          <br />
          <br />
          Si l&#x27;acheteur souhaite que tout ou partie de ces documents soient
          divulgués à/ou utilisés par un tiers, il doit en demander
          l&#x27;autorisation préalable par écrit au prestataire. Des modalités
          applicables à cette divulgation seront alors fixées.
          <br />
          <br />
          <br />
          Informations exclues
          <br />
          <br />
          Les obligations et restrictions énoncées ci-dessus ne
          s&#x27;appliquent pas : aux informations confidentielles qui
          appartiennent au domaine public, ou ont été acquises librement avant
          le début de la prestation ;<br />
          <br />
          sont ou deviennent connues autrement qu&#x27;à la suite d&#x27;une
          violation du présent article ;<br />
          <br />
          sont ou deviennent connues grâce à d&#x27;autres sources non tenues à
          une restriction de divulgation ;<br />
          <br />
          ou doivent être communiquées en vertu d&#x27;une obligation légale ou
          professionnelle ou à la demande de toute autorité judiciaire ou
          réglementaire habilitée à exiger la divulgation des informations
          confidentielles. Sous réserve de ses obligations en matière de
          confidentialité, le prestataire se réserve le droit d&#x27;exécuter
          des prestations pour des entreprises concurrentes de celle de
          l&#x27;acheteur.
        </p>
        <h2 className="article__heading">
          Article 15 - Propriété intellectuelle
        </h2>
        <p className="paragraph">
          1. Droits de Propriété Intellectuelle de Tiers
          <br />
          <br />
          Au cas où l’une des recommandations du prestataire ou l’utilisation
          d’éléments livrés à la suite de l’une de ses préconisations
          impliquerait l’utilisation de biens, modèles, dessins, photographies,
          etc., faisant l’objet de droits de propriété intellectuelle
          appartenant à des tiers, le prestataire informera l’acheteur de
          l’existence de ces droits et des conséquences de leur utilisation. Il
          appartiendra alors à l’acheteur et sous sa seule responsabilité de
          prendre toute mesure permettant l’utilisation de tels droits.
          <br />
          <br />
          2. Propriété du Code Source de l’Application
          <br />
          <br />
          Le code source de l’application développée spécifiquement pour
          l’acheteur est la propriété de l’acheteur, sous réserve que toutes les
          factures dues au prestataire aient été payées en totalité et que tous
          les devis acceptés par le client aient été soldés. Cette propriété
          inclut la cession des droits patrimoniaux du prestataire à l’acheteur,
          pour le monde entier, et pour toute la durée de protection légale des
          droits d’auteur, sur tous supports et pour tous modes d’exploitation.
          <br />
          <br />
          Cette cession comprend les droits de reproduction, de représentation,
          d’adaptation, de modification, de traduction, et de commercialisation
          de l’application, à titre exclusif ou non, conformément aux
          dispositions du Code de la propriété intellectuelle.
          <br />
          <br />
          Toutefois, cette propriété est limitée au code source de l’application
          elle-même et n’inclut pas le dashboard de gestion ou tout autre
          logiciel édité par la société SAS minuit.agency.
          <br />
          <br />
          3. Licence du Dashboard de Gestion
          <br />
          <br />
          L’acheteur n’a qu’un droit de licence sur le dashboard de gestion
          (“minuit.cloud”), qui est un logiciel privé appartenant à la société
          SAS minuit.agency. Ce droit de licence est limité à un droit
          d’utilisation non exclusif, personnel et non transférable, pour la
          durée du contrat, et ne comporte aucun droit de propriété
          intellectuelle sur le dashboard.
          <br />
          <br />
          4. Droits sur les Logiciels Édités par la Société
          <br />
          <br />
          L’acheteur n’a aucun droit sur les logiciels édités par la société SAS
          minuit.agency, à l’exception d’un simple droit d’utilisation. Ce droit
          d’utilisation est limité à la durée du contrat et ne peut être étendu
          sans l’accord écrit préalable de la société SAS minuit.agency.
          <br />
          <br />
          5. Autres dispositions
          <br />
          <br />
          Pour les besoins des prestations, le prestataire pourra utiliser ou
          développer des outils, méthodes, processus et savoir-faire qui
          resteront sa propriété exclusive. L’acheteur pourra, sans limitation
          géographique et à titre gratuit, utiliser les éléments conçus
          spécifiquement pour lui pour la durée de protection par le droit
          d’auteur.
          <br />
          <br />
          Aucune partie ne pourra faire mention ou usage du nom, de la
          dénomination, des marques et logos ou autres appellations,
          commerciales ou non, de l’autre partie sans accord préalable et écrit
          de cette dernière.
          <br />
          <br />
          Par dérogation à ce qui précède, le prestataire pourra faire usage du
          nom, de la dénomination, des marques et logos de l’acheteur dans la
          mesure de ce qui est strictement nécessaire à l’exécution des
          prestations.
          <br />
          <br />
          L’acheteur autorise également le prestataire, à l’issue de la
          réalisation des prestations, à citer son nom ou sa dénomination à
          titre de référence et à accompagner cette citation d’une description
          générique des prestations effectuées.
        </p>
        <h2 className="article__heading">Article 16 - Documents</h2>
        <p className="paragraph">
          Le prestataire conservera les documents originaux qui lui auront été
          remis, et les restituera à l&#x27;acheteur, sur sa demande. Tous les
          documents, données ou informations, que l&#x27;acheteur aura fournies,
          resteront sa propriété. Le prestataire conservera une copie des seuls
          documents nécessaires à la constitution de ses dossiers de travail.
          Les documents de travail préparés dans le cadre des prestations sont
          notre propriété et sont couverts par le secret Les documents de
          travail préparés dans le cadre des prestations sont notre propriété et
          sont couverts par le secret professionnel.
        </p>
        <h2 className="article__heading">Article 17 - Indépendance</h2>
        <p className="paragraph">
          Dans l&#x27;hypothèse où un conflit d&#x27;intérêt ou une
          problématique d&#x27;indépendance surviendrait au cours de
          l&#x27;exécution des prestations, le prestataire en fera part
          immédiatement à l&#x27;acheteur et recherchera avec lui la solution la
          plus adaptée à la situation dans le respect des règles applicables.
          Plus particulièrement, si une modification de la réglementation ou des
          normes professionnelles interdisait au prestataire de poursuivre ses
          prestations, il mettra à la disposition de l&#x27;acheteur le résultat
          des prestations ainsi que tous documents nécessaires à leur
          finalisation, y compris ses Documents en l&#x27;état, et ce, afin
          d&#x27;en faciliter la poursuite par un tiers.
        </p>
        <h2 className="article__heading">
          Article 18 - Responsabilité du prestataire
        </h2>
        <p className="paragraph">
          L’entière responsabilité du prestataire et celle de ses collaborateurs
          relative à tout manquement, négligence ou faute, relevé à l’occasion
          de l’exécution des prestations, sera plafonnée au montant des
          honoraires versés au titre des prestations mises en cause, afin de
          couvrir les réclamations de toute nature (intérêts et frais inclus),
          et ce, quel que soit le nombre d’actions, de fondements invoqués, ou
          de parties aux litiges.
          <br />
          <br />
          Cette stipulation ne s’appliquera pas en cas de faute lourde ou
          dolosive du prestataire, ni en cas de responsabilité pour décès ou
          blessure corporelle, ni à toute autre responsabilité que la loi
          interdit d’exclure ou de limiter.
          <br />
          <br />
          La responsabilité du prestataire ne peut être engagée qu’en cas de
          faute ou de négligence prouvée et est limitée aux préjudices directs,
          à l’exclusion de tout préjudice indirect, de quelque nature que ce
          soit.
          <br />
          <br />
          Par ailleurs, la responsabilité du prestataire ne pourra être engagée
          dans les cas suivants :<br />
          <br />• Suite à un manquement ou à une carence d’un produit ou d’un
          service dont la fourniture ou la livraison ne lui incombe pas ni à ses
          sous-traitants éventuels ;
          <br />• Pour les faits et/ou données qui n’entrent pas dans le
          périmètre des prestations, et/ou qui n’en sont pas le prolongement ;
          <br />• En cas d’utilisation des résultats des prestations pour un
          objet ou dans un contexte différent de celui dans lequel il est
          intervenu, de mise en œuvre erronée des recommandations ou d’absence
          de prise en compte des réserves du prestataire.
          <br />
          <br />
          Le prestataire ne répond, ni ses assureurs, des dommages indirects, ni
          du manque à gagner ou de la perte de chance ou de bénéfices escomptés,
          ni des conséquences financières des actions éventuellement intentées
          par des tiers à l’encontre de l’acheteur.
          <br />
          <br />
          La faute lourde sera définie comme une négligence ou un manquement
          particulièrement grave, commis en violation manifeste des obligations
          essentielles définies dans le présent contrat, et dont les
          conséquences étaient prévisibles par le prestataire au moment de
          l’exécution de la prestation. Cette définition sera interprétée
          strictement au regard des usages et de la relation professionnelle des
          parties.
        </p>
        <h2 className="article__heading">
          Article 19 - Étapes du projet, échéances de facturation, validation et
          accès au code source
        </h2>
        <p className="paragraph">
          Dans le cadre de nos prestations de services, le processus de
          développement de projet se déroule selon plusieurs étapes clés,
          accompagnées d’échéances de facturation précises et de procédures de
          validation, pour assurer une transparence et une compréhension
          mutuelle entre minuit.agency et le client. Les conditions financières
          et procédures de validation pour chaque étape sont les suivantes :
          <br />
          <br />
          Acompte initial : À la signature du contrat, le client s’engage à
          verser un acompte de 40 % du montant total estimé du projet. Cet
          acompte marque le lancement officiel du projet et couvre les premières
          phases de conception et de planification.
          <br />
          <br />
          <br />
          Validation du design et règlement de 30 % :
          <br />
          <br />
          Après la livraison du design via l’application “minuit.app”, le client
          dispose d’un délai de huit (8) jours ouvrables pour fournir ses
          commentaires ou son approbation. Le prestataire s’engage à envoyer un
          rappel au client à l’issue de ce délai si aucune réponse n’a été
          reçue. En l’absence de retour du client dans un délai de cinq (5)
          jours ouvrables après ce rappel, le design sera considéré comme
          accepté par le client. À la validation du design par le client, une
          facture de 30 % du montant total du projet sera émise, et le
          développement commencera à réception du règlement de cette facture.
          <br />
          <br />
          <br />
          Livraison de la recette et règlement du solde de 30 % :
          <br />
          <br />
          La phase de recette se fera exclusivement à travers l’application
          “minuit.app” mise à disposition par minuit.agency. Après l’envoi d’un
          mail de mise à disposition du projet, le client a trente (30) jours
          pour effectuer la recette et transmettre ses commentaires ou son
          approbation. Le prestataire s’engage à envoyer un rappel au client
          quinze (15) jours avant l’échéance de ce délai.
          <br />
          <br />À défaut de retour du client à l’issue de ce délai, le projet
          sera considéré comme livré et accepté sans réserve. La réception de la
          recette par minuit.agency et le règlement du solde final déclencheront
          la finalisation de cette étape.
          <br />
          <br />
          Traitement des tâches de la recette : minuit.agency traitera les
          tâches identifiées lors de la phase de recette conformément au devis
          initial, après réception et règlement du solde final.
          <br />
          <br />
          Signature du PV de recette et bon de livraison : Suite au traitement
          des tâches de la recette, le client signera un Procès-Verbal de
          Recette et un Bon de Livraison, attestant de la conformité du livrable
          avec les exigences convenues.
          <br />
          <br />
          En cas de non-signature injustifiée du procès-verbal de recette dans
          les 15 jours suivant la fin de la phase de recette, le prestataire
          sera en droit de facturer une pénalité de retard équivalente à 0,5 %
          du montant total du contrat par jour de retard, sans préjudice des
          autres actions que le prestataire pourrait engager.
          <br />
          <br />
          Accès au code source : Après la signature du PV de recette et du bon
          de livraison, et sous réserve que toutes les factures aient été
          intégralement réglées et tous les devis validés par le client aient
          été soldés, minuit.agency fournira au client l’accès au code source du
          projet.
          <br />
          <br />
          Cet ensemble d’étapes, d’échéances de facturation, et de procédures de
          validation est conçu pour structurer le déroulement du projet de
          manière efficace, en s’assurant de la pleine satisfaction du client et
          de la conformité du produit final avec les standards de qualité de
          minuit.agency.
        </p>
        <h2 className="article__heading">
          Article 20 – Garantie et Maintenance
        </h2>
        <p className="paragraph">
          Garantie :
          <br />
          <br />
          a. Garanties légalesLe <br />
          <br />
          Prestataire garantit l’Acheteur contre tout défaut de conformité et
          tout vice caché provenant d’un défaut de conception ou de fourniture
          des Services, conformément aux dispositions des articles 1641 et
          suivants du Code civil, à condition que ces derniers ne résultent pas
          d’une négligence ou faute de l’Acheteur ou d’une utilisation non
          conforme aux spécifications techniques fournies.
          <br />
          <br />
          b. Exclusion de <br />
          <br />
          GarantieLes technologies sous-jacentes telles que Firebase, NextJS,
          React Native, etc., utilisées pour la fourniture des Services, ne sont
          pas garanties par le Prestataire. Toute garantie concernant ces
          technologies relève de la responsabilité de leurs éditeurs respectifs.
          <br />
          <br />
          c. Limitation de Responsabilité
          <br />
          <br />
          Dans le cas où la responsabilité du Prestataire serait engagée, cette
          dernière sera limitée au montant HT payé par l’Acheteur pour la
          fourniture des Services, sous réserve des dispositions légales
          impératives.
          <br />
          <br />
          2. Maintenance :
          <br />
          <br />
          a. Période de Maintenance
          <br />
          <br />
          La maintenance des Services débutera à compter de la signature du
          Procès-Verbal de Réception et s’étendra sur une durée maximale de huit
          (8) mois, dans la limite de deux cents (200) tickets. L’acheteur
          reconnaît et accepte expressément cette limitation.
          <br />
          <br />
          b. Nature de la Maintenance
          <br />
          <br />
          La maintenance sera exclusivement corrective, incluant la prise en
          charge des corrections et ajustements nécessaires pour garantir la
          conformité et le bon fonctionnement des Services fournis, selon les
          termes du contrat. Toute demande de développement de nouvelles
          fonctionnalités sera exclue de la maintenance et fera l’objet de devis
          distincts à travers l’application “minuit.app”.
          <br />
          <br />
          c. Soumission des Tickets
          <br />
          <br />
          Les tickets devront être soumis par l’Acheteur exclusivement à travers
          l’application “minuit.app” ou par mail.
          <br />
          <br />
          d. Exclusions
          <br />
          <br />
          Les prestations suivantes sont exclues des Services couverts par le
          contrat de niveau de services, sauf disposition contraire expressément
          stipulée dans le contrat :
          <br />
          <br />• Configuration de logiciels indépendants du contrat de niveau
          de services.
          <br />• Services fournis par d’autres prestataires.
          <br />• Problèmes de software et/ou hardware dans l’environnement du
          client.
          <br />• Défaillances causées par des attaques par déni de service.
          <br />
          <br />
          e. Traitement des Requêtes
          <br />
          <br />
          Le prestataire s’engage à traiter les tickets de maintenance selon le
          principe du “Best Effort”, avec une distinction des priorités comme
          suit : urgences critiques (interruption du service) traitées sous 24
          heures, autres tickets sous 30 jours ouvrables. Les tickets soumis en
          dehors de ces délais seront traités dans la limite des capacités
          opérationnelles du prestataire.
          <br />
          <br />
          3. Tickets Supplémentaires :
          <br />
          <br />
          a. Achat de Tickets Supplémentaires
          <br />
          <br />
          Le Client pourra acquérir des tickets supplémentaires au-delà de la
          limite de deux cents (200) tickets initialement prévue, sur simple
          demande à travers l’application “minuit.app”.
          <br />
          <br />
          b. Tarification
          <br />
          <br />
          Le prix des tickets supplémentaires sera déterminé en fonction de la
          nature et de la complexité de la tâche à accomplir. Un devis détaillé
          sera fourni au Client via l’application “minuit.app” pour chaque
          ticket supplémentaire demandé. Aucun travail supplémentaire ne sera
          engagé sans l’approbation explicite du Client sur le devis proposé.
        </p>
        <h2 className="article__heading">
          Article 21 - Cybersécurité et protection des systèmes
        </h2>
        <p className="paragraph">
          Le prestataire met en œuvre des mesures techniques et
          organisationnelles conformes aux standards du secteur pour assurer la
          sécurité des livrables contre les accès non autorisés, les intrusions,
          les altérations ou les destructions de données. Toutefois, l’acheteur
          reconnaît que, compte tenu de l’évolution rapide des technologies et
          des menaces, le prestataire ne peut garantir une sécurité absolue.
          <br />
          <br />
          Le prestataire ne saurait être tenu responsable des dommages résultant
          d’attaques informatiques, de virus, de logiciels malveillants ou de
          tout autre événement affectant les systèmes de l’acheteur, sauf si ces
          dommages sont directement imputables à une faute du prestataire dans
          la mise en œuvre des mesures de sécurité convenues.
        </p>
        <h2 className="article__heading">
          Article 22 - Respect des licences des logiciels tiers et composants
          Open Source
        </h2>
        <p className="paragraph">
          <br />
          Les livrables peuvent inclure des logiciels tiers ou des composants
          Open Source soumis à des licences spécifiques. Le prestataire s’engage
          à respecter les termes de ces licences et à fournir à l’acheteur les
          informations nécessaires sur les droits et obligations associés.
          <br />
          <br />
          L’acheteur s’engage à utiliser ces logiciels et composants
          conformément aux conditions de leurs licences respectives et à ne pas
          en détourner l’usage. Le prestataire ne saurait être tenu responsable
          d’une utilisation non conforme par l’acheteur desdits logiciels ou
          composants.
        </p>
        <h2 className="article__heading">
          Article 23 - Intégration avec des services tiers et API
        </h2>
        <p className="paragraph">
          Les prestations peuvent inclure l’intégration des livrables avec des
          services ou des interfaces de programmation d’applications (API)
          fournis par des tiers. Le prestataire ne garantit pas la pérennité, la
          disponibilité ni les performances de ces services tiers.
          <br />
          <br />
          En conséquence, le prestataire ne pourra être tenu responsable des
          dysfonctionnements, interruptions, modifications ou cessations de ces
          services tiers affectant le fonctionnement des livrables. Il
          appartient à l’acheteur de souscrire aux services tiers nécessaires,
          de respecter leurs conditions d’utilisation et de veiller à leur
          compatibilité.
        </p>
        <h2 className="article__heading">
          Article 24 - Mises à jour technologiques et obsolescence
        </h2>
        <p className="paragraph">
          Les technologies informatiques évoluant rapidement, le prestataire
          développe les livrables en fonction des standards et des versions
          disponibles au moment de leur conception. Le prestataire ne saurait
          être tenu responsable de l’obsolescence des livrables due à
          l’évolution des technologies, des systèmes d’exploitation ou des
          plateformes matérielles.
          <br />
          <br />
          Toute demande de mise à jour, d’adaptation ou d’évolution des
          livrables pour les rendre compatibles avec de nouvelles technologies,
          versions de systèmes d’exploitation ou matériels fera l’objet d’un
          devis spécifique et d’un accord écrit entre les parties.
        </p>
        <h2 className="article__heading">
          Article 25 - Sauvegarde et récupération des données
        </h2>
        <p className="paragraph">
          Le prestataire s’engage à mettre en place des procédures de sauvegarde
          régulière des données liées aux livrables durant la phase de
          développement et, le cas échéant, pendant la période de maintenance
          prévue au contrat. Ces sauvegardes ont pour but de prévenir toute
          perte ou altération des données dues à des incidents techniques.
          <br />
          <br />
          En dehors de cette période, il appartient à l’acheteur de mettre en
          œuvre ses propres procédures de sauvegarde et de sécurité des données.
          Le prestataire ne saurait être tenu responsable de la perte ou de
          l’altération des données de l’acheteur survenant après la livraison
          des livrables, sauf en cas de faute avérée du prestataire.
        </p>
        <h2 className="article__heading">
          Article 26 - Responsabilité limitée en matière de performance
        </h2>
        <p className="paragraph">
          Le prestataire s’engage à fournir les livrables conformément aux
          spécifications convenues, mais ne garantit pas que ceux-ci
          fonctionneront sans interruption ni erreur, ni qu’ils répondront à des
          exigences de performance particulières non expressément stipulées dans
          le contrat.
          <br />
          <br />
          L’acheteur est responsable de vérifier que les performances des
          livrables correspondent à ses besoins et contraintes opérationnelles.
          Toute demande d’optimisation ou d’amélioration des performances non
          prévue au contrat initial fera l’objet d’un devis séparé.
        </p>
        <h2 className="article__heading">
          Article 27 - Cessibilité et sous-traitance
        </h2>
        <p className="paragraph">
          Le prestataire se réserve le droit de céder tout ou partie de
          l&#x27;exécution des prestations à des prestataires répondant aux
          mêmes exigences de qualification. Si la prestation requiert des
          compétences techniques particulières, le prestataire informera
          l&#x27;acheteur sur la possibilité d&#x27;en sous-traiter une partie.
          Le sous-traitant interviendra alors sous la seule responsabilité du
          prestataire et s&#x27;engagera à conserver confidentielles toutes les
          informations dont il aura connaissance à l&#x27;occasion des
          prestations.
        </p>
        <h2 className="article__heading">Article 28 - Non-solicitation</h2>
        <p className="paragraph">
          Les parties s’engagent à renoncer au recrutement de tout collaborateur
          ou salarié de l’autre partie. Chaque partie renonce ainsi expressément
          à tenter de recruter ou à faire travailler directement ou
          indirectement le personnel de l’autre partie. À ce titre, elles
          renoncent à tenter et à inciter les collaborateurs et les salariés à
          quitter leur entreprise pour le partenaire commercial de cette
          dernière. De même, les parties s’engagent à ne pas donner suite aux
          sollicitations provenant d’un salarié lui-même désireux de quitter son
          entreprise pour la seconde.
          <br />
          <br />
          Cette obligation mutuelle pèse sur les parties durant toute la durée
          des relations commerciales. Elle se poursuit durant deux ans une fois
          la fin de ces dernières.
          <br />
          <br />
          Le non-respect de cette clause entraîne le versement d’une pénalité
          égale à six mois de salaire brut du salarié concerné, de la part de la
          partie défaillante à son cocontractant.
        </p>
        <h2 className="article__heading">Article 29 - Réclamations</h2>
        <p className="paragraph">
          Toutes les réclamations, qu’elles soient amiables ou judiciaires,
          relatives à l’exécution des prestations devront être formulées dans le
          délai légal de prescription de droit commun, soit cinq ans à compter
          de la fin de la réalisation de la prestation.
        </p>
        <h2 className="article__heading">Article 30 - Droit de rétractation</h2>
        <p className="paragraph">
          L&#x27;acheteur étant un professionnel achetant dans le cadre et pour
          les besoins de sa profession, il n&#x27;y a pas lieu d&#x27;appliquer
          le droit de rétractation prévu par le code de la consommation.
        </p>
        <h2 className="article__heading">Article 31 - Force majeure</h2>
        <p className="paragraph">
          Toutes circonstances indépendantes de la volonté des parties,
          empêchant l&#x27;exécution dans des conditions normales de leurs
          obligations, sont considérées comme des causes d&#x27;exonération des
          obligations des parties et entraînent leur suspension. La partie qui
          invoque les circonstances visées ci-dessus doit avertir immédiatement
          l&#x27;autre partie de leur survenance, ainsi que de leur disparition.
          Seront considérés comme cas de force majeure tous faits ou
          circonstances irrésistibles, extérieurs aux parties, imprévisibles,
          inévitables, indépendants de la volonté des parties et qui ne pourront
          être empêchés par ces dernières, malgré tous les efforts
          raisonnablement possibles. De façon expresse, sont considérés comme
          cas de force majeure ou cas fortuits, outre ceux habituellement
          retenus par la jurisprudence des cours et des tribunaux français : le
          blocage des moyens de transports ou d&#x27;approvisionnements,
          tremblements de terre, incendies, tempêtes, inondations, foudre,
          l&#x27;arrêt des réseaux de télécommunication ou difficultés propres
          aux réseaux de télécommunication externes aux clients. Les parties se
          rapprocheront pour examiner l&#x27;incidence de l&#x27;événement et
          convenir des conditions dans lesquelles l&#x27;exécution du contrat
          sera poursuivie. Si le cas de force majeure a une durée supérieure à
          trois mois, les présentes conditions générales pourront être résiliées
          par la partie lésée.
        </p>
        <h2 className="article__heading">
          Article 32 - Non-validation partielle
        </h2>
        <p className="paragraph">
          Si une ou plusieurs stipulations des présentes conditions générales
          sont tenues pour non valides ou déclarées telles en application
          d&#x27;une loi, d&#x27;un règlement ou à la suite d&#x27;une décision
          définitive d&#x27;une juridiction compétente, les autres stipulations
          garderont toute leur force et leur portée.
        </p>
        <h2 className="article__heading">Article 33 - Non-renonciation</h2>
        <p className="paragraph">
          Le fait pour l&#x27;une des parties de ne pas se prévaloir d&#x27;un
          manquement par l&#x27;autre partie à l&#x27;une quelconque des
          obligations visées dans les présentes conditions générales ne saurait
          être interprété pour l&#x27;avenir comme une renonciation à
          l&#x27;obligation en cause.
        </p>
        <h2 className="article__heading">Article 34 - Titre</h2>
        <p className="paragraph">
          En cas de difficulté d&#x27;interprétation entre l&#x27;un quelconque
          des titres figurant en tête des clauses, et l&#x27;une quelconque des
          clauses, les titres seront déclarés inexistants.
        </p>
        <h2 className="article__heading">
          Article 35 - Protection des données personnelles
        </h2>
        <p className="paragraph">
          1. Données Collectées
          <br />
          <br />
          MINUIT.AGENCY collecte et traite les données à caractère personnel et
          les données d’entreprise nécessaires à la fourniture et à
          l’amélioration de ses services de conception, de design, et de
          développement de solutions digitales. Les catégories de données
          collectées incluent :
          <br />
          <br />• Nom, prénom, adresse électronique, adresse postale, et numéro
          de téléphone des clients ;
          <br />• Échanges avec les clients via divers moyens de communication,
          y compris WhatsApp, email, et tout autre canal de communication
          utilisé dans le cadre de nos services ;
          <br />• Informations relatives à la société du client, telles que la
          raison sociale, l’adresse de l’entreprise, le numéro de TVA, le SIRET,
          et autres informations pertinentes ;
          <br />• Toutes les données transmises ou générées via minuit.app,
          incluant mais sans se limiter aux rapports de bug, tickets créés, et
          commentaires envoyés par les clients concernant les projets.
          <br />
          <br />
          Utilisation des Données
          <br />
          <br />
          Les données personnelles et d’entreprise collectées sont utilisées sur
          la base légale de l’exécution du contrat, pour les finalités
          suivantes :
          <br />‍<br />• Faciliter l’accès et l’utilisation de nos services par
          les clients ;
          <br />• Gérer et optimiser notre site web et nos services sur
          minuit.app ;
          <br />• Assurer l’organisation et la gestion des conditions
          d’utilisation des services de paiement ;
          <br />• Vérifier, identifier, et authentifier les données transmises
          par les clients ;
          <br />• Fournir une assistance utilisateurs et gérer les services
          après-vente, y compris le suivi des rapports de bug et des tickets ;
          <br />• Prévenir et détecter les fraudes, les logiciels malveillants,
          et gérer les incidents de sécurité ;
          <br />• Gérer les éventuels litiges avec les clients ;
          <br />• Envoyer des informations commerciales et publicitaires selon
          les préférences du client, avec son consentement préalable.
          <br />
          <br />
          3. Partage des Données avec des Tiers
          <br />‍<br />
          Les données personnelles et d’entreprise peuvent être partagées avec
          des tiers dans les cas suivants :
          <br />
          <br />• Avec le consentement explicite du client pour que des services
          tiers accèdent à ses données ;
          <br />• Lorsque nous faisons appel à des prestataires pour
          l’assistance utilisateurs, la publicité, et les services de paiement,
          avec un accès limité aux données nécessaires à l’exécution de ces
          services, et sous obligation contractuelle de respecter la
          confidentialité et la sécurité des données ;
          <br />• En réponse à des obligations légales, pour répondre aux
          réclamations contre MINUIT.AGENCY et se conformer aux procédures
          administratives et judiciaires ;
          <br />• En cas de restructuration d’entreprise, impliquant le
          transfert ou le partage de données, avec garantie de la protection des
          données et information préalable aux clients.
          <br />
          <br />
          4. Sécurité et Confidentialité
          <br />
          <br />
          MINUIT.AGENCY implémente des mesures organisationnelles, techniques,
          logicielles, et physiques appropriées pour protéger les données
          personnelles et d’entreprise contre les risques de sécurité. Nous nous
          engageons à informer nos clients en cas de faille de sécurité
          affectant leurs données, conformément aux obligations légales.
          <br />
          <br />
          5. Droits des Utilisateurs
          <br />
          <br />
          Les clients disposent des droits suivants sur leurs données
          personnelles :
          <br />• Droit d’accès ;
          <br />• Droit de rectification ;
          <br />• Droit à l’effacement ;
          <br />• Droit à la limitation du traitement ;
          <br />• Droit d’opposition ;
          <br />• Droit à la portabilité.
          <br />
          <br />
          Ces droits peuvent être exercés en contactant hello@minuit.agency,
          accompagnés d’une preuve d’identité.
          <br />
          <br />
          6. Durée de Conservation des Données
          <br />
          <br />
          Les données personnelles sont conservées pendant la durée nécessaire à
          l’exécution du contrat et conformément aux obligations légales.
          <br />
          <br />
          7. Modifications de la Politique de Protection des Données
          <br />
          <br />
          MINUIT.AGENCY se réserve le droit de modifier cette politique. En cas
          de modification, la nouvelle version sera publiée sur notre site et
          communiquée par email au moins 15 jours avant son application. Les
          clients mécontents des changements peuvent exercer leur droit
          d’opposition ou demander la suppression de leurs données.
        </p>
        <h2 className="article__heading">Article 36 - Résiliation anticipée</h2>
        <p className="paragraph">
          1. Résiliation pour manquement
          <br />
          <br />
          Chaque partie pourra résilier de plein droit le contrat en cas de
          manquement grave par l’autre partie à l’une quelconque de ses
          obligations essentielles, après mise en demeure par lettre recommandée
          avec accusé de réception restée sans effet pendant un délai de quinze
          (15) jours calendaires à compter de sa réception. La mise en demeure
          devra indiquer expressément la nature du manquement invoqué.
          <br />
          <br />
          2. Résiliation pour force majeure
          <br />
          <br />
          En cas de force majeure telle que définie à l’Article 25 des présentes
          conditions générales, si l’empêchement dépasse une durée de trois (3)
          mois consécutifs, le contrat pourra être résilié de plein droit par
          l’une ou l’autre des parties, par lettre recommandée avec accusé de
          réception, sans indemnité de part et d’autre.
          <br />
          <br />
          3. Résiliation pour convenance
          <br />
          <br />
          L’acheteur a la faculté de résilier le contrat pour convenance, sous
          réserve de notifier sa décision au prestataire par lettre recommandée
          avec accusé de réception en respectant un préavis de trente (30) jours
          calendaires. <br />
          <br />
          Dans ce cas, l’acheteur s’engage à régler au prestataire :<br />• Les
          prestations déjà réalisées à la date de prise d’effet de la
          résiliation, sur la base du travail effectivement accompli et des
          dépenses engagées ;<br />• Une indemnité forfaitaire égale à vingt
          pour cent (20 %) du montant total restant dû au titre du contrat, en
          réparation du préjudice subi par le prestataire du fait de la
          résiliation anticipée.
          <br />
          <br />
          4. Conséquences de la résiliation
          <br />
          <br />
          En cas de résiliation du contrat, pour quelque cause que ce soit :
          <br />• Les droits concédés à l’acheteur au titre du contrat cesseront
          immédiatement, sous réserve des dispositions relatives à la propriété
          intellectuelle ;<br />• L’acheteur restituera au prestataire, à
          première demande, tous les documents, informations, logiciels et, plus
          généralement, tous les éléments dont il pourrait avoir eu connaissance
          ou possession dans le cadre de l’exécution du contrat, sous quelque
          forme que ce soit ;<br />• Le prestataire remettra à l’acheteur
          l’ensemble des livrables réalisés et payés jusqu’à la date de
          résiliation, y compris le code source correspondant, sous réserve du
          paiement intégral des sommes dues.
          <br />
          <br />
          5. Clause pénale
          <br />
          En cas de résiliation du contrat du fait d’un manquement de l’acheteur
          à ses obligations, notamment en cas de défaut de paiement ou de
          violation des obligations de collaboration, le prestataire sera en
          droit de conserver les sommes déjà versées par l’acheteur à titre de
          dommages et intérêts, sans préjudice de toute action qu’il pourrait
          engager pour obtenir réparation intégrale du préjudice subi.
          <br />
          <br />
          6. Assistance à la transition
          <br />À la demande expresse de l’acheteur, formulée par écrit au plus
          tard dans les quinze (15) jours calendaires suivant la date de prise
          d’effet de la résiliation, le prestataire pourra fournir une
          assistance pour faciliter la transition vers un nouveau prestataire.
          Les conditions de cette assistance seront définies d’un commun accord
          entre les parties et feront l’objet d’un devis spécifique.
          <br />
          <br />
          7. Survie des clauses
          <br />
          Les dispositions du contrat qui, par leur nature ou leur objet, sont
          destinées à survivre à sa résiliation (notamment les clauses relatives
          à la confidentialité, à la propriété intellectuelle, à la
          responsabilité, et au règlement des litiges) demeureront en vigueur
          nonobstant la résiliation du contrat.
        </p>
        <h2 className="article__heading">
          Article 37 - Non-concurrence sur le savoir-faire
        </h2>
        <p className="paragraph">
          L’acheteur s’engage à ne pas reproduire, commercialiser ou utiliser,
          de manière directe ou indirecte, les méthodologies, processus,
          technologies ou savoir-faire développés par minuit.agency dans le
          cadre de prestations similaires à celles fournies par l’agence, pour
          une durée de deux ans à compter de la fin des prestations. Toute
          violation de cette clause entraînera le paiement d’une indemnité égale
          à 50 % du montant total des prestations concernées.
        </p>
        <h2 className="article__heading">
          Article 38 - Renégociation en cas de modification législative
        </h2>
        <p className="paragraph">
          En cas de modification législative ou réglementaire affectant
          directement les obligations contractuelles du prestataire, celui-ci se
          réserve le droit de proposer une révision des termes du contrat pour
          s’adapter à ces nouvelles obligations. À défaut d’accord dans un délai
          de 30 jours à compter de la notification écrite de la demande de
          révision, chaque partie pourra résilier le contrat sans indemnité.
        </p>
        <h2 className="article__heading">
          Article 39 - Loi applicable et règlement des litiges
        </h2>
        <p className="paragraph">
          Les présentes conditions générales sont soumises à l’application du
          droit français. Elles sont rédigées en langue française. En cas de
          traduction en une ou plusieurs langues, seul le texte français fera
          foi en cas de litige.
          <br />
          <br />
          Médiation préalable
          <br />
          <br />
          En cas de différend relatif à l’exécution ou à l’interprétation des
          présentes conditions générales, les parties s’engagent à rechercher
          une solution amiable. À défaut d’accord amiable dans un délai de
          trente (30) jours calendaires à compter de la notification écrite du
          différend par l’une des parties, elles conviennent de recourir à une
          procédure de médiation, conformément aux dispositions des articles
          1530 et suivants du Code de procédure civile.
          <br />
          <br />
          La médiation sera conduite par un médiateur agréé choisi d’un commun
          accord entre les parties. Les frais de médiation seront partagés à
          parts égales entre les parties, sauf accord contraire.
          <br />
          <br />
          Compétence juridictionnelle
          <br />
          <br />À défaut de résolution du litige par la voie de la médiation
          dans un délai de soixante (60) jours à compter de la désignation du
          médiateur, les parties attribuent compétence exclusive aux tribunaux
          compétents de Bordeaux pour connaître de tout litige relatif à la
          validité, l’interprétation, l’exécution ou la résiliation des
          présentes conditions générales, y compris en matière de référé,
          d’appel en garantie ou de pluralité de défendeurs.
        </p>
      </div>
    </section>
  );
};

export default GeneralConditionSection;
