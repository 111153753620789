"use client";

import React, { useEffect, useLayoutEffect } from "react";
import ProjectCard from "../../../../components/ProjectCard";
import EmptyState from "../../../../components/EmptyState";

import projects from "../../../../data/projects.json";
import Each from "../../../../components/Each";

declare const document: any;

const scrollContainer = document.querySelector(".projects");
const projectCards = document.querySelectorAll(".project__card");

const ProjectsSectionCollection: React.FC = () => {
  const checkIfFullyVisible = (card: any) => {
    // Get the position of the project card relative to the scroll container
    const containerRect = scrollContainer?.getBoundingClientRect();
    const cardRect = card.getBoundingClientRect();
    // Check if the card is fully visible in the horizontal scroll container
    const fullyVisible =
      cardRect.left >= containerRect!.left &&
      cardRect.right <= containerRect!.right;
    // Add or remove the class based on visibility
    if (fullyVisible) {
      card.classList.add("fully-visible");
    } else {
      card.classList.remove("fully-visible");
    }
  };

  const checkAllCardsVisibility = () => {
    // Only perform visibility checks on mobile devices
    if (window.innerWidth <= 768) {
      // Loop through all project cards and check their visibility
      projectCards.forEach((card: any) => {
        checkIfFullyVisible(card);
      });
    } else {
      // Remove the class from all cards when not on mobile
      projectCards.forEach((card: any) => {
        card.classList.remove("fully-visible");
      });
    }
  };

  useEffect(() => {
    // Listen for scroll events on the scroll container
    scrollContainer?.addEventListener("scroll", checkAllCardsVisibility);
    // Optionally, check visibility on window resize
    window.addEventListener("resize", checkAllCardsVisibility);

    // Initial check for visibility when the page loads
    checkAllCardsVisibility();
  }, []);

  useLayoutEffect(() => {
    // Initial check for visibility when the page loads
    checkAllCardsVisibility();
  }, []);

  return (
    <div
      id="w-node-e32ebefb-b828-cef8-e4a2-1e6a00f7e5ec-df22aa20"
      className="project-collection w-dyn-list"
    >
      {projects?.length ? (
        <div role="list" className="projects w-dyn-items">
          <Each
            of={projects}
            render={(project, index) => (
              <div
                role="listitem"
                className="w-dyn-item"
                key={`project_${index}`}
              >
                <ProjectCard
                  projectBrandImgSrc={project?.icon}
                  projecTitle={project?.title}
                  projectDescription={project?.description}
                  projectCardImgSrc={project?.image}
                />
              </div>
            )}
          />
        </div>
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

export default ProjectsSectionCollection;
