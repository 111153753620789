import React from "react";
import MakeAppointmentButton from "../../../../components/MakeAppointmentButton";
import OurAchievementsButton from "./OurAchievementsButton";
import HeroContactLinks from "./HeroContactLinks";

const HeroSectionContent: React.FC = () => {
  return (
    <div className="hero-content">
      <div className="block block-hide-mobile"></div>
      <div className="block">
        <h1 className="hero__title">Nous donnons vie à vos projets digitaux</h1>
        <p className="hero__text">
          Nos experts et technologies à votre service
        </p>
        <div className="button-group">
          <MakeAppointmentButton showAvatar />
          <OurAchievementsButton />
        </div>
      </div>
      <HeroContactLinks />
    </div>
  );
};

export default HeroSectionContent;
