import React from "react";
import MakeAppointmentButton from "../../../../components/MakeAppointmentButton";

const images = [
  {
    id: "1",
    src: "images/minuitAppFeatures/artificialAgent.png",
    srcSet:
      "https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b652da498026887d3258e_ipad-home-5-p-500.png 500w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b652da498026887d3258e_ipad-home-5-p-800.png 800w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b652da498026887d3258e_ipad-home-5-p-1080.png 1080w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b652da498026887d3258e_ipad-home-5.png 1295w",
  },
  {
    id: "2",
    src: "images/minuitAppFeatures/workSession.png",
    srcSet:
      "https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b652111bf5448abc8656e_ipad-home-4-p-500.png 500w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b652111bf5448abc8656e_ipad-home-4-p-800.png 800w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b652111bf5448abc8656e_ipad-home-4-p-1080.png 1080w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b652111bf5448abc8656e_ipad-home-4.png 1295w",
  },
  {
    id: "3",
    src: "images/minuitAppFeatures/lot.png",
    srcSet:
      "https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b6519348704112f60d394_ipad-home-3-p-500.png 500w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b6519348704112f60d394_ipad-home-3-p-800.png 800w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b6519348704112f60d394_ipad-home-3-p-1080.png 1080w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b6519348704112f60d394_ipad-home-3.png 1295w",
  },
  {
    id: "4",
    src: "images/minuitAppFeatures/task.png",
    srcSet:
      "https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b650bbe02a7c9ccc30085_ipad-home-2-p-500.png 500w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b650bbe02a7c9ccc30085_ipad-home-2-p-800.png 800w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b650bbe02a7c9ccc30085_ipad-home-2-p-1080.png 1080w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b650bbe02a7c9ccc30085_ipad-home-2.png 1295w",
  },
  {
    id: "5",
    src: "images/minuitAppFeatures/delayEstimate.png.png",
    srcSet:
      "https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b6502b4adbb41faba2744_ipad-home-1-p-500.png 500w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b6502b4adbb41faba2744_ipad-home-1-p-800.png 800w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b6502b4adbb41faba2744_ipad-home-1-p-1080.png 1080w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b6502b4adbb41faba2744_ipad-home-1.png 1295w",
  },
  {
    id: "6",
    src: "images/minuitAppFeatures/chat.png",
    srcSet:
      "https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b64f44353766e352bb327_ipad-home-p-500.png 500w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b64f44353766e352bb327_ipad-home-p-800.png 800w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b64f44353766e352bb327_ipad-home-p-1080.png 1080w, https://cdn.prod.website-files.com/66f106b91fe1651eb4dc2a68/674b64f44353766e352bb327_ipad-home.png 1295w",
  },
];

const AppSectionRightContent: React.FC = () => {
  return (
    <div
      id="w-node-_2530280f-c890-b981-72a0-783c16f5c9a3-df22aa20"
      className="app__right-content"
    >
      <img
        data-w-id="6aabf712-85e9-695a-8c5a-a3ff85acdce7"
        sizes="(max-width: 479px) 100vw, (max-width: 767px) 93vw, 100vw"
        alt=""
        src="images/ipad-home.png"
        loading="lazy"
        srcSet="images/ipad-home-p-500.png 500w, images/ipad-home-p-800.png 800w, images/ipad-home.png 867w"
        className="app__image--initial w-dyn-bind-empty"
      />
      <div className="content-list w-dyn-list">
        <div role="list" className="w-dyn-items">
          {images.map((image) => (
            <div key={image.id} role="listitem" className="w-dyn-item">
              <img
                id={image.id}
                loading="lazy"
                alt=""
                src={image.src}
                sizes="100vw"
                srcSet={image.srcSet}
                className="app__image"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="button-group desktop-hidden">
        <MakeAppointmentButton showAvatar />
      </div>
    </div>
  );
};

export default AppSectionRightContent;
