import React, { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  id?: string;
  wId?: string;
  className?: string;
  itemId?: string;
  itemStyle?: React.CSSProperties;
}

const ScrollContainer: React.FC<Props> = ({
  id,
  className,
  itemId,
  wId,
  itemStyle,
  children,
}) => {
  return (
    <div id={id} className={`scroll-container ${className}`}>
      <div
        id={itemId}
        data-w-id={wId}
        style={itemStyle}
        className="scroll-item"
      >
        {children}
      </div>
    </div>
  );
};

export default ScrollContainer;
