import React from "react";

const NavBarLeftContent: React.FC = () => {
  return (
    <div className="left-content">
      <a href="/" aria-current="page" className="logo w-nav-brand w--current">
        <img
          loading="lazy"
          src="images/logoMain.svg"
          alt=""
          className="image-17"
        />
      </a>
      <div className="navigation">
        <a href="/#presentation" className="nav-link w-nav-link">
          Présentation
        </a>
        <a href="/#realisations" className="nav-link w-nav-link">
          Réalisations
        </a>
        <a href="/#avis" className="nav-link w-nav-link">
          Avis
        </a>
        <a href="/#faqs" className="nav-link w-nav-link">
          FAQ
        </a>
      </div>
    </div>
  );
};

export default NavBarLeftContent;
