import * as React from "react";

const IconUserCircleuserSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5773 4.25C8.02068 4.25 4.32733 7.94421 4.32733 12.5C4.32733 17.0567 8.02063 20.75 12.5773 20.75C17.134 20.75 20.8273 17.0567 20.8273 12.5C20.8273 7.94421 17.1339 4.25 12.5773 4.25ZM2.82733 12.5C2.82733 7.11589 7.19215 2.75 12.5773 2.75C17.9625 2.75 22.3273 7.11589 22.3273 12.5C22.3273 17.8851 17.9624 22.25 12.5773 22.25C7.1922 22.25 2.82733 17.8851 2.82733 12.5Z"
      fill="#F3F0F5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5549 18.6687C8.97132 19.1253 8.69565 19.7096 8.62597 20.2513C8.57314 20.6622 8.19727 20.9524 7.78644 20.8996C7.37561 20.8467 7.08539 20.4708 7.13823 20.06C7.25537 19.1491 7.71915 18.2005 8.63055 17.4873C9.54016 16.7756 10.8407 16.3398 12.5698 16.3398C14.3149 16.3398 15.6232 16.7773 16.5353 17.4953C17.4499 18.2152 17.9078 19.1728 18.0196 20.0913C18.0697 20.5025 17.777 20.8764 17.3658 20.9265C16.9546 20.9765 16.5807 20.6838 16.5306 20.2726C16.4635 19.721 16.1901 19.1325 15.6076 18.6739C15.0225 18.2134 14.0677 17.8398 12.5698 17.8398C11.0901 17.8398 10.1403 18.2107 9.5549 18.6687Z"
      fill="#F3F0F5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.577 9.83203C11.4899 9.83203 10.6085 10.7134 10.6085 11.8005C10.6085 12.8876 11.4899 13.769 12.577 13.769C13.6641 13.769 14.5455 12.8876 14.5455 11.8005C14.5455 10.7134 13.6641 9.83203 12.577 9.83203ZM9.10858 11.8005C9.10858 9.88501 10.6615 8.33203 12.577 8.33203C14.4925 8.33203 16.0455 9.88501 16.0455 11.8005C16.0455 13.716 14.4925 15.269 12.577 15.269C10.6615 15.269 9.10858 13.716 9.10858 11.8005Z"
      fill="#F3F0F5"
    />
  </svg>
);
export default IconUserCircleuserSVG;
