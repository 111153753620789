import React from "react";

type Props = {
  testimony?: string;
  clientName?: string;
  clientRole?: string;
  clientProfilePicture?: string;
  projectName?: string;
};

const TestimonialCard: React.FC<Props> = ({
  testimony = "",
  clientName = "",
  clientRole = "",
  clientProfilePicture = "",
  projectName,
}) => {
  return (
    <div className="testimonial__card">
      <p className="testimonial__card-body">{testimony}</p>
      <div className="testimonial__card-user">
        <div className="div-block-4">
          <img
            loading="lazy"
            src={clientProfilePicture}
            alt=""
            className="testimonial__card-profile"
          />
        </div>
        <div>
          <div className="text-block-10">{clientName}</div>
          <div className="text-block-11">
            {clientRole} @{" "}
            <span
              style={{
                color: "#fb68a8",
              }}
            >
              {projectName}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
