import React from "react";

const OurAchievementsButton = () => {
  return (
    <a
      data-w-id="f30b8b36-a05a-9967-0f72-932d1f54e87e"
      href="#realisations"
      className="button button--outline w-inline-block"
    >
      <div className="button-overflow">
        <div
          style={{
            WebkitTransform:
              "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            MozTransform:
              "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            transform:
              "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
          }}
          className="button-text"
        >
          Nos réalisations
        </div>
        <div
          style={{
            WebkitTransform:
              "translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            MozTransform:
              "translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            transform:
              "translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
          }}
          className="button-text-absolute"
        >
          Nos réalisations
        </div>
      </div>
    </a>
  );
};

export default OurAchievementsButton;
