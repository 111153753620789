import React from "react";

const phoneImages = [
  {
    id: "ac0066f9-1d86-a22c-4f79-3b5ca87c4898",
    className: "cta__iphone-1",
    srcSet:
      "images/Flat-iPhone-1-min-p-500.png 500w, images/Flat-iPhone-1-min.png 914w",
    src: "images/Flat-iPhone-1-min.png",
    alt: "",
  },
  {
    id: "ac0066f9-1d86-a22c-4f79-3b5ca87c489a",
    className: "cta__iphone-02",
    srcSet:
      "images/Flat-iPhone-2-min-p-500.png 500w, images/Flat-iPhone-2-min-p-800.png 800w, images/Flat-iPhone-2-min.png 849w",
    src: "images/Flat-iPhone-2-min.png",
    alt: "",
  },
  {
    id: "ac0066f9-1d86-a22c-4f79-3b5ca87c489c",
    className: "cta__iphone-3",
    srcSet:
      "images/Flat-iPhone-3-min-p-500.png 500w, images/Flat-iPhone-3-min.png 812w",
    src: "images/Flat-iPhone-3-min.png",
    alt: "",
  },
  {
    id: "ac0066f9-1d86-a22c-4f79-3b5ca87c489e",
    className: "cta__iphone-4",
    srcSet:
      "images/Flat-iPhone-4-min-p-500.png 500w, images/Flat-iPhone-4-min-p-800.png 800w, images/Flat-iPhone-4-min.png 895w",
    src: "images/Flat-iPhone-4-min.png",
    alt: "",
  },
  {
    id: "ac0066f9-1d86-a22c-4f79-3b5ca87c48a0",
    className: "cta__iphone-5",
    srcSet:
      "images/Flat-iPhone-5-min-p-500.png 500w, images/Flat-iPhone-5-min-p-800.png 800w, images/Flat-iPhone-5-min.png 913w",
    src: "images/Flat-iPhone-5-min.png",
    alt: "",
  },
];

const CTASectionPhone: React.FC = () => {
  return (
    <div
      data-w-id="ac0066f9-1d86-a22c-4f79-3b5ca87c4897"
      className="cta__phone-wrapper"
    >
      {phoneImages.map((phone) => (
        <div key={phone.id} data-w-id={phone.id} className={phone.className}>
          <img
            sizes="100vw"
            srcSet={phone.srcSet}
            alt={phone.alt}
            src={phone.src}
            loading="lazy"
            className="phone__image"
          />
        </div>
      ))}
    </div>
  );
};

export default CTASectionPhone;
