import React from "react";

const BentoSectionContentMobile = () => {
  return (
    <div
      id="w-node-_987d4060-4713-aaf2-6669-1a986553df56-df22aa20"
      className="bento__card glowing_border mobile"
    >
      <div className="overlay"></div>
      <div className="div-block-12">
        <img
          loading="lazy"
          src="images/minuitIcon.svg"
          alt=""
          className="image-33"
        />
        <div className="bento__card-text">
          <h3 className="bento-cad__title-copy">Suivi en temps réel</h3>
          <p className="paragraph-2">
            Suivez les avancées de votre projet en temps réel où que vous soyez!
          </p>
        </div>
      </div>
      <img
        sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, 100vw"
        srcSet="images/ipad-home-1-p-500.png 500w, images/ipad-home-1-p-800.png 800w, images/ipad-home-1-p-1080.png 1080w, images/ipad-home-1-p-1600.png 1600w, images/ipad-home-1-p-2000.png 2000w, images/ipad-home-1-p-2600.png 2600w, images/ipad-home-1.png 2892w"
        alt=""
        src="images/ipad-home-1.png"
        loading="lazy"
        className="image-3"
      />
      <img
        src="images/Notification.png"
        loading="lazy"
        sizes="100vw"
        srcSet="images/Notification-p-500.png 500w, images/Notification-p-800.png 800w, images/Notification-p-1080.png 1080w, images/Notification.png 1448w"
        alt=""
        className="notification-image _1"
      />
      <img
        src="images/Notification-3.png"
        loading="lazy"
        sizes="100vw"
        srcSet="images/Notification-3-p-500.png 500w, images/Notification-3-p-800.png 800w, images/Notification-3-p-1080.png 1080w, images/Notification-3.png 1448w"
        alt=""
        className="notification-image _2"
      />
      <img
        src="images/Flat-iPhone.png"
        loading="lazy"
        sizes="(max-width: 479px) 83vw, (max-width: 767px) 150px, 100vw"
        srcSet="images/Flat-iPhone-p-500.png 500w, images/Flat-iPhone-p-800.png 800w, images/Flat-iPhone.png 957w"
        alt=""
        className="phone-image"
      />
    </div>
  );
};

export default BentoSectionContentMobile;
