import "./CTASectionMobile.scss";

import React from "react";

const images = [
  {
    sizes: "(max-width: 479px) 218px, 100vw",
    srcSet:
      "images/Flat-iPhone-1-p-500.png 500w, images/Flat-iPhone-1-p-800.png 800w, images/Flat-iPhone-1.png 1076w",
    src: "images/Flat-iPhone-1.png",
  },
  {
    sizes: "(max-width: 479px) 230px, 100vw",
    srcSet:
      "images/Flat-iPhone-2-p-500.png 500w, images/Flat-iPhone-2-p-800.png 800w, images/Flat-iPhone-2-minn.png 1000w",
    src: "images/Flat-iPhone-2-minn.png",
  },
  {
    sizes: "(max-width: 479px) 242px, 100vw",
    srcSet:
      "images/Flat-iPhone-3-p-500.png 500w, images/Flat-iPhone-3-p-800.png 800w, images/Flat-iPhone-3.png 1055w",
    src: "images/Flat-iPhone-3.png",
  },
  {
    sizes: "(max-width: 479px) 254px, 100vw",
    srcSet:
      "images/Flat-iPhone-4-p-500.png 500w, images/Flat-iPhone-4-p-800.png 800w, images/Flat-iPhone-4.png 1112w",
    src: "images/Flat-iPhone-4.png",
  },
  {
    sizes: "(max-width: 479px) 220px, 100vw",
    srcSet:
      "images/Flat-iPhone-5-p-500.png 500w, images/Flat-iPhone-5-p-800.png 800w, images/Flat-iPhone-5.png 1075w",
    src: "images/Flat-iPhone-5.png",
  },
];

const CTASectionMobile: React.FC = () => {
  return (
    <div
      data-w-id="ac0066f9-1d86-a22c-4f79-3b5ca87c48a2"
      className="cta__phone-wrapper--mobile"
      style={{ position: "relative", width: "100%" }}
    >
      <img
        alt="Prendre rendez-vous avec minuit.agency"
        src={"images/mobileCtaStatic.png"}
        style={{
          maxWidth: "200%",
          maxHeight: 500,
          position: "absolute",
          bottom: "-35%",
        }}
      />
    </div>
  );
};

export default CTASectionMobile;
