import React from "react";
import KPIItem from "../../../../components/KPIItem";

const KPISection: React.FC = () => {
  const kpiItems = [
    {
      operand: "+",
      label: "Projets réalisés",
      value: 0,
      duration: 2000,
      start: 0,
      end: 100,
      valueClassName: "text-block-18",
      labelClassName: "kpi__number transformed-kpi-text",
    },
    {
      label: "Années d’expériences",
      value: 0,
      duration: 1000,
      start: 0,
      end: 15,
    },
    {
      label: "D’euros levés",
      value: 0,
      duration: 1000,
      start: 0,
      end: 3,
      suffix: "M",
    },
  ];

  return (
    <section className="section-kpi">
      <div className="kpi-container">
        {kpiItems.map((item, index) => (
          <KPIItem key={index} {...item} />
        ))}
      </div>

      <div
        data-poster-url="videos/5354737_Coll_wavebreak_Digital_1280x720-poster-00001.jpg"
        data-video-urls="videos/5354737_Coll_wavebreak_Digital_1280x720-transcode.mp4,videos/5354737_Coll_wavebreak_Digital_1280x720-transcode.webm"
        data-autoplay="true"
        data-loop="true"
        data-wf-ignore="true"
        className="background-video-4 w-background-video w-background-video-atom"
      >
        <video
          id="258eb0ce-c2ca-d869-081c-35279c16debd-video"
          autoPlay
          loop
          muted
          playsInline
          data-wf-ignore="true"
          data-object-fit="cover"
          style={{
            backgroundImage: `url('videos/5354737_Coll_wavebreak_Digital_1280x720-poster-00001.jpg')`,
          }}
        >
          <source
            src="videos/5354737_Coll_wavebreak_Digital_1280x720-transcode.mp4"
            data-wf-ignore="true"
          />
          <source
            src="videos/5354737_Coll_wavebreak_Digital_1280x720-transcode.webm"
            data-wf-ignore="true"
          />
        </video>
        <div className="div-block-17"></div>
      </div>
    </section>
  );
};

export default KPISection;
