import React from "react";
import MakeAppointmentButton from "../../MakeAppointmentButton";

const CTASectionHeader: React.FC = () => {
  return (
    <div className="cta__header">
      <h3 className="cta__heading">Discutons de votre projet</h3>
      <p className="cta__text">
        Réservez votre créneau gratuit de 30 minutes durant lequel nous
        analyseront vos besoins pour vous apporter des solutions adaptées.
      </p>
      <MakeAppointmentButton showAvatar />
    </div>
  );
};

export default CTASectionHeader;
