import React from "react";

const BentoSectionContentCardMobile = () => {
  return (
    <div
      id="w-node-_36b5b5e8-ac7a-53bb-6da1-ce0eece3318f-df22aa20"
      className="bento__card bento__card--big bento__card--mobile"
    >
      <div className="overlay"></div>
      <img
        loading="lazy"
        src="images/blur-top.svg"
        alt=""
        className="blur-top-right-2"
      />
      <div className="div-block-3">
        <img
          loading="lazy"
          src="images/minuitIcon.svg"
          alt=""
          className="image-16"
        />
        <div className="bento__card-text">
          <h3 className="bento-cad__title-copy">Suivi en temps réel</h3>
          <p className="paragraph-2">
            Suivez les avancées de votre projet en temps réel où que vous soyez!
          </p>
        </div>
      </div>
      <div className="image-container">
        <img
          sizes="100vw"
          srcSet="images/-bentoCardBig-p-500.png 500w, images/-bentoCardBig-p-800.png 800w, images/-bentoCardBig-p-1080.png 1080w, images/-bentoCardBig.png 1446w"
          alt=""
          src="images/-bentoCardBig.png"
          loading="lazy"
          className="image-3"
        />
      </div>
    </div>
  );
};

export default BentoSectionContentCardMobile;
