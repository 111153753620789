import React from "react";

import faqs from "../../../data/faqs.json";
import Each from "../../Each";
import FAQItem from "../../FAQItem/FAQItem";
import EmptyState from "../../EmptyState";

const FAQSection: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);

  return (
    <section id="faqs" className="section-faq">
      <div className="faq-container">
        <h2 className="heading-3">Questions fréquentes</h2>
        <div className="faq-wrapper">
          <div className="faqs-collection w-dyn-list">
            {faqs?.length ? (
              <div role="list" className="w-dyn-items">
                <Each
                  of={faqs}
                  render={(faq, index) => (
                    <FAQItem
                      title={faq.title}
                      description={faq.description}
                      key={`faq_item_${index}`}
                      isOpen={selectedIndex === index}
                      onPress={() =>
                        setSelectedIndex(selectedIndex === index ? null : index)
                      }
                    />
                  )}
                />
              </div>
            ) : (
              <EmptyState />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
