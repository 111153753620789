import React from "react";
import { onBookMeeting } from "../helpers";

type Props = {
  showAvatar?: boolean;
  containerClass?: string;
};

const MakeAppointmentButton: React.FC<Props> = ({
  showAvatar,
  containerClass,
}) => {
  return (
    <a
      data-w-id="765b9d4c-ce5a-5337-4b97-6947ffc039d8"
      onClick={onBookMeeting}
      target="_blank"
      rel="noreferrer"
      className={`button w-inline-block ${
        showAvatar ? "button--sm-padding" : null
      } ${containerClass}`}
    >
      <div className="button-overflow">
        <div className="button-text">Prendre rendez-vous</div>
        <div className="button-text-absolute">Prendre rendez-vous</div>
      </div>
      {showAvatar && (
        <img
          loading="lazy"
          src="images/memojiBookCall.png"
          alt=""
          className="button-image"
        />
      )}
    </a>
  );
};

export default MakeAppointmentButton;
